<template>
    <div class="frontBackaAppraisal" id="frontBackaAppraisal" ref="capture">
        <!-- 顶部筛选 -->
        <div class="filtrate" id="entrance">
            <el-select v-model="termValue" :popper-append-to-body="false" placeholder="请选择" @change="termChange">
                <el-option v-for="(item, index) in termList" :key="index" :label="item.termName" :value="item.key">
                </el-option>
            </el-select>
            <el-select v-model="reportValue" :popper-append-to-body="false" placeholder="请选择" @change="reportChange">
                <el-option v-for="item in reportList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <el-select v-model="monthValue" :popper-append-to-body="false" placeholder="请选择" @change="monthChange"
                v-if="reportValue == '4'">
                <el-option v-for="item in monthList" :key="item.create_at" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <el-select v-model="weekValue" :popper-append-to-body="false" placeholder="请选择" @change="weekChange"
                v-if="reportValue == '5'">
                <el-option v-for="item in weekList" :key="item.create_at" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <div class="export flex-align">
                <div class="wrap flex-align">
                    <div class="flex-align"
                        @click="reportValue == '4' ? exportReportmonth() : reportValue == '5' ? exportReportweek() : exportReport()"
                        v-if="exportReport_DownLoadType">
                        <img src="../../assets/newReport/export.png" alt="">
                        导出数据
                    </div>
                    <div class="flex-align" v-else>
                        <span>下载中<i class="el-icon-loading"></i></span>
                    </div>
                </div>
                <div class="wrap flex-align">
                    <span
                        @click="reportValue == '4' ? handleDownLoadmonth() : reportValue == '5' ? handleDownLoadweek() : handleDownLoad()"
                        v-if="DownLoadType">下载报告</span>
                    <span v-else>下载中<i class="el-icon-loading"></i></span>

                </div>
            </div>
        </div>
        <!-- 顶部筛选 -->
        <template v-if="reportValue != '4' && reportValue != '5' && isEmpty">
            <!-- 学校名称-学期 -->
            <div class="type_title flex-column-center" id="type_title">
                <h1>{{ schoolName }}{{ currentReportType }}</h1>
                <h2>{{ currentTerm }}</h2>
            </div>
            <!-- 学校名称-学期 -->
            <div class="overall_situation">
                <h1 class="First_level_heading">1.整体情况</h1>
                <!-- 参与情况 -->
                <div class="participation" id="pdf1">
                    <div class="cephalosome flex-align-between">
                        <div class="Secondary_heading">
                            <div class="vertical_grain_icon"></div> <span class="title">参与情况</span>
                        </div>
                    </div>
                    <div class="substance flex-between">
                        <div class="participation_form">
                            <div class="particulars">
                                <Short_form :construction="participation_construction" :tabledata="joinData"
                                    :row_high="row_height1" slashType="close">
                                </Short_form>
                            </div>
                            <div class="conclusion" v-if="joinData.length > 0">
                                <span class="prefix_title">结论1</span>
                                本次测评活动共有<span class="Comment_highlight"> {{ stuCount }} </span>个学生参与，
                                共回收到有效测评试卷<span class="Comment_highlight"> {{ pageCount }} </span>页（每份试卷两页）。
                                <span v-if="joinData.length > 1 && !comment_one">
                                    其中<span class="Comment_highlight"> {{ compare(joinData, 'participationRate').maxRate }}
                                    </span>
                                    参与率最高，<span class="Comment_highlight">
                                        {{ compare(joinData, 'participationRate').minRate }}
                                    </span>参与率最低。
                                </span>
                            </div>
                        </div>
                        <div class="participation_graphics">
                            <participationRate :joinData="joinRate_graphics" :termKey="termValue"></participationRate>
                            <!-- <participationRate :graphicsData="participation_graphics" :termKey="termValue"></participationRate> -->
                        </div>
                    </div>

                </div>
                <!-- 书写习惯 -->
                <div class="participation">
                    <div class="Sittingposture" id="pdf2">
                        <div class="cephalosome flex-align-between">
                            <!-- //书写习惯-坐姿 -->
                            <div class="Secondary_heading">
                                <div class="vertical_grain_icon"></div> <span class="title">书写习惯-坐姿</span>
                            </div>
                        </div>
                        <div class="substance flex-between">
                            <div class="participation_form">
                                <div class="particulars">
                                    <img src="../../assets/newReport/sitting.png" alt="">
                                    <!-- :high="Height2" :row_high="row_height2" -->
                                    <Short_form :construction="sitting_construction" :tabledata="sitting_Data"
                                        slashType="close"></Short_form>
                                </div>
                            </div>
                            <div class="sitting_graphics">
                                <incorrectDemonstration :graphicsData="participation_graphics" :termKey="termValue">
                                </incorrectDemonstration>
                            </div>
                        </div>
                        <div class="sitting_conclusion conclusion" v-if="sitting_Data.length > 0">
                            <span class="prefix_title">结论2</span>
                            针对坐姿，有<span class="Comment_highlight">{{ sitCount }}</span>名学生出现坐姿错误，占全校的<span
                                class="Comment_highlight">{{ sitRate }}%</span>。
                            <span v-if="sitting_Data.length > 1 && !comment_two">
                                其中<span class="Comment_highlight">
                                    {{ compare(sitting_Data, 'ratio').maxRate }}</span>错误率最高，
                                <span class="Comment_highlight">{{ compare(sitting_Data, 'ratio').minRate }}</span>错误率最低。
                            </span>
                        </div>
                    </div>
                    <div class="Sittingposture" id="pdf3">
                        <div class="cephalosome flex-align-between interval">
                            <!-- //书写习惯-握笔姿势 -->
                            <div class="Secondary_heading">
                                <div class="vertical_grain_icon"></div> <span class="title">书写习惯-握笔姿势</span>
                            </div>
                        </div>
                        <div class="substance flex-between">
                            <div class="participation_form">
                                <div class="particulars">
                                    <img src="../../assets/newReport/hold.png" alt="">
                                    <!-- :high="Height3" :row_high="row_height3" -->
                                    <Short_form :construction="hold_construction" :tabledata="hold_Data" slashType="close">
                                    </Short_form>
                                </div>
                            </div>
                            <div class="sitting_graphics">
                                <incorrectDemonstration :graphicsData="hold_graphics"></incorrectDemonstration>
                            </div>
                        </div>

                        <div class="sitting_conclusion conclusion" v-if="hold_Data.length > 0">
                            <span class="prefix_title">结论3</span>
                            针对握笔姿势有<span class="Comment_highlight">{{ holdCount }}</span>名学生使用了错误的握笔姿势，占全校的<span
                                class="Comment_highlight">{{ holdRate }}%</span>。
                            <span v-if="hold_Data.length > 1 && !comment_three">
                                其中<span class="Comment_highlight">{{
                                    compare(hold_Data, 'ratio').maxRate }}</span>的错误率最高，<span class="Comment_highlight">{{
        compare(hold_Data, 'ratio').minRate }}</span>错误率最低。
                            </span>
                        </div>
                    </div>
                    <div class="Sittingposture" id="pdf4">
                        <div class="cephalosome flex-align-between interval">
                            <!-- //书写习惯-坐姿 -->
                            <div class="Secondary_heading">
                                <div class="vertical_grain_icon"></div> <span class="title">书写结果判断握笔姿势</span>
                            </div>
                        </div>
                        <div class="substance flex-align-center">
                            <div class="hold_category flex-column-center">
                                <img>
                                <el-image style="width: 80%;"
                                    :src="sitimg ? require('../../assets/newReport/wobi2.png') : require('../../assets/newReport/wobi1.png')"
                                    :preview-src-list="workList2">
                                </el-image>
                            </div>
                        </div>
                        <div class="changeset flex-align-center ">
                            <div class="changeset_btn flex-align-center" @click="toggle">换一组查看</div>
                        </div>
                    </div>
                </div>
                <!-- 全校各等级学员占比 -->
                <div class="participation" id="pdf5">
                    <div class="cephalosome flex-align-between">
                        <!-- //标题 -->
                        <div class="Secondary_heading">
                            <div class="vertical_grain_icon"></div> <span class="title">全校各等级学员占比</span>
                        </div>
                    </div>
                    <div class="substance flex-between">
                        <div class="grade_proportion table_scrollbar">
                            <schoolLevelTable v-if="table_type == 2" :termValue="termValue" :reportType="correctReportType"
                                :newComment="newComment" :reportCode="reportCode"></schoolLevelTable>
                            <el-table v-else :data="levelData" :stripe="true" :fit="false" empty-text="暂无数据">
                                <el-table-column prop="level" label="等级占比" align="center" width="60"> </el-table-column>
                                <el-table-column label="章法占格" align="center">
                                    <el-table-column prop="layout_1" label="卷面整洁" align="center" width="70"
                                        :formatter="formatCleanliness">
                                    </el-table-column>
                                    <el-table-column prop="layout_2" label="居中占格" align="center" width="70"
                                        :formatter="formatCleanliness">
                                    </el-table-column>
                                    <el-table-column prop="layout_3" label="占格大小" align="center" width="70"
                                        :formatter="formatCleanliness">
                                    </el-table-column>
                                </el-table-column>
                                <el-table-column label="结构组合" align="center">
                                    <el-table-column prop="structure_1" label="字形重心" align="center" width="70"
                                        :formatter="formatCleanliness">
                                    </el-table-column>
                                    <el-table-column prop="structure_2" label="收放关系" align="center" width="70"
                                        :formatter="formatCleanliness">
                                    </el-table-column>
                                    <el-table-column prop="structure_3" label="空间布白" align="center" width="70"
                                        :formatter="formatCleanliness">
                                    </el-table-column>
                                    <el-table-column prop="structure_4" label="组合关系" align="center" width="70"
                                        :formatter="formatCleanliness">
                                    </el-table-column>
                                </el-table-column>
                                <el-table-column label="笔画形态" align="center">
                                    <el-table-column prop="form_1" label="笔画方向" align="center" width="70"
                                        :formatter="formatCleanliness">
                                    </el-table-column>
                                    <el-table-column prop="form_2" label="运笔笔性" align="center" width="70"
                                        :formatter="formatCleanliness">
                                    </el-table-column>
                                    <el-table-column prop="form_3" label="笔画粗细" align="center" width="70"
                                        :formatter="formatCleanliness">
                                    </el-table-column>
                                </el-table-column>
                                <el-table-column prop="complete_1" label="作品完整度" align="center" width="70"
                                    :formatter="formatCleanliness">
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="grade_proportion_graphics">
                            <grade_graphics :seriesData="seriesData" :table_type="table_type"></grade_graphics>
                        </div>
                    </div>
                    <div class="hold_mistake flex-column-center">
                        <h1>与上学期等级对比</h1>
                        <div class="hold_mistake_table table_scrollbar">
                            <el-table :data="holdData" style="width: 100%" :stripe="true" empty-text="暂无数据">
                                <el-table-column prop="term" label="学期" align="center">
                                </el-table-column>
                                <el-table-column prop="level1" :label="table_type == 1 ? '优秀+' : '优秀'" align="center">
                                    <template slot-scope="scope">
                                        <!-- v-if="scope.row.level1 != '0'" -->
                                        <div class="imgUrlbox"> <img :src="imgUrl[`imgUrl${scope.row.level1_compare}`]"
                                                alt=""></div>
                                        <!-- scope.row.level1 == '0' ? '/' :scope.row.level1 + '%' -->
                                        <span style="margin-left: 10px">{{ scope.row.level1 }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="level2" :label="table_type == 1 ? '优秀' : '良好'" align="center">
                                    <template slot-scope="scope">
                                        <div class="imgUrlbox"> <img :src="imgUrl[`imgUrl${scope.row.level2_compare}`]"
                                                alt=""></div>
                                        <span style="margin-left: 10px">{{ scope.row.level2 }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="level3" :label="table_type == 1 ? '良好' : '合格'" align="center">
                                    <template slot-scope="scope">
                                        <div class="imgUrlbox"> <img :src="imgUrl[`imgUrl${scope.row.level3_compare}`]"
                                                alt=""></div>
                                        <span style="margin-left: 10px">{{ scope.row.level3 }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="level4" label="待提升" align="center">
                                    <template slot-scope="scope">
                                        <div class="imgUrlbox"> <img :src="imgUrl[`imgUrl${scope.row.level4_compare}`]"
                                                alt=""></div>
                                        <span style="margin-left: 10px">{{ scope.row.level4 }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="hold_mistake_conclusion conclusion" v-if="comment4">
                            <span class="prefix_title">结论4</span>
                            <template v-if="table_type == 2">
                                <p>本校学生综合成绩来看，平均成绩{{ comment4.k1 }}等级的学生居多。{{ table_type == 1 ? '优秀率' : '优良率' }}为<span
                                        class="Comment_highlight"> {{
                                            comment4.k2
                                        }}%
                                    </span>，相对上学期有所{{ comment4.k3 }}。</p>
                                <p>（1）居中占位：<span class="Comment_highlight">{{ computedComment4('center_pos').levelname
                                }}</span>水平人数最多，
                                    占比为<span class="Comment_highlight">{{ computedComment4('center_pos').value }}%</span>，
                                    {{
                                        computedComment4('center_pos').rate }}的学生
                                    {{ comments4['center_pos'][computedComment4('center_pos').levelname] }}
                                </p>
                                <p>（2）占格大小：<span class="Comment_highlight">{{ computedComment4('grid_size').levelname
                                }}</span>水平人数最多，
                                    占比为<span class="Comment_highlight">{{ computedComment4('grid_size').value }}%</span>， {{
                                        computedComment4('grid_size').rate }}的学生
                                    {{ comments4['grid_size'][computedComment4('grid_size').levelname] }}</p>
                                <p>（3）字形重心：<span class="Comment_highlight">{{ computedComment4('glyph_grav').levelname
                                }}</span>水平人数最多，
                                    占比为<span class="Comment_highlight">{{ computedComment4('glyph_grav').value }}%</span>，
                                    {{
                                        computedComment4('glyph_grav').rate }}的学生
                                    {{ comments4['glyph_grav'][computedComment4('glyph_grav').levelname] }}</p>
                                <p>（4）组合关系：<span class="Comment_highlight">{{ computedComment4('combo_rel').levelname
                                }}</span>水平人数最多，
                                    占比为<span class="Comment_highlight">{{ computedComment4('combo_rel').value }}%</span>， {{
                                        computedComment4('combo_rel').rate }}的学生
                                    {{ comments4['combo_rel'][computedComment4('combo_rel').levelname] }}</p>
                                <p>（5）收放关系：<span class="Comment_highlight">{{ computedComment4('rewind_rel').levelname
                                }}</span>水平人数最多，
                                    占比为<span class="Comment_highlight">{{ computedComment4('rewind_rel').value }}%</span>，
                                    {{
                                        computedComment4('rewind_rel').rate }}的学生
                                    {{ comments4['rewind_rel'][computedComment4('rewind_rel').levelname] }}</p>
                                <p>（6）多画等距：<span class="Comment_highlight">{{ computedComment4('multi_dist').levelname
                                }}</span>水平人数最多，
                                    占比为<span class="Comment_highlight">{{ computedComment4('multi_dist').value }}%</span>，
                                    {{
                                        computedComment4('multi_dist').rate }}的学生
                                    {{ comments4['multi_dist'][computedComment4('multi_dist').levelname] }}</p>
                                <p>（7）笔画关系：<span class="Comment_highlight">{{ computedComment4('stroke_rel').levelname
                                }}</span>水平人数最多，
                                    占比为<span class="Comment_highlight">{{ computedComment4('stroke_rel').value }}%</span>，
                                    {{
                                        computedComment4('stroke_rel').rate }}的学生
                                    {{ comments4['stroke_rel'][computedComment4('stroke_rel').levelname] }}</p>
                                <p>（8）作品完整度：<span class="Comment_highlight">{{ computedComment4('integrity').levelname
                                }}</span>水平人数最多，
                                    占比为<span class="Comment_highlight">{{ computedComment4('integrity').value }}%</span>， {{
                                        computedComment4('integrity').rate }}的学生
                                    {{ comments4['integrity'][computedComment4('integrity').levelname] }}</p>
                            </template>
                            <template v-else>
                                <p>本校学生综合成绩来看，平均成绩{{ comment4.k1 }}等级的学生居多。优秀率为<span class="Comment_highlight"> {{
                                    comment4.k2
                                }}%
                                    </span>，相对上学期有所{{ comment4.k3 }}。</p>
                                <p>(1)<span class="Comment_highlight">
                                        章法占格</span>上，{{ comment4.layout }}</p>
                                <p>(2)<span class="Comment_highlight">
                                        结构组合</span>上，{{ comment4.structure }}</p>
                                <p>(3)<span class="Comment_highlight">
                                        笔画形态</span>上，{{ comment4.form }}</p>
                                <p>(4)<span class="Comment_highlight">
                                        作品完整度</span>上，{{ comment4.complete }}</p>
                            </template>
                        </div>
                    </div>

                </div>
                <!-- 一级维度全校成绩对比 -->
                <div class="participation" id="pdf6">
                    <div class="cephalosome flex-align-between">
                        <div class="Secondary_heading">
                            <div class="vertical_grain_icon"></div> <span class="title">各维度全校成绩对比</span>
                        </div>
                    </div>

                    <old_School_score :tablaData_school="tablaData_school" :tableData_compare="tableData_compare"
                        :seriesData="various_graphics_data1" :variousData="variousData1" :variousType="currentReportType"
                        :comment5="comment5" v-if="table_type == 1"></old_School_score>
                    <new_School_score :tablaData_school="tablaData_school" :tableData_compare="tableData_compare"
                        :seriesData="various_graphics_data2" :variousData="variousData2" :variousType="currentReportType"
                        :comment5="comment5" v-else></new_School_score>

                </div>
                <!-- 各年级分析 -->
                <div class="flex-between interval2">
                    <h1 class="First_level_heading">2.各年级分析</h1>
                    <!-- <div class="xx">
                        <el-select v-model="grade_value" placeholder="请选择" @change="changeGtade"
                            :popper-append-to-body="false">
                            <el-option v-for="item in grade_options" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div> -->
                </div>
                <div v-for="(item, index) in Gradelist" :key="index">
                    <GradeAnalysis :Grade="Gradetype[item]" :schoolName="schoolName" :termValue="termValue"
                        :ref="`myChildRef${index}`" :reportCode="reportCode" :table_type="table_type"
                        :correctReportType="correctReportType"></GradeAnalysis>
                </div>
            </div>
        </template>
        <template v-else-if="reportValue == '4' && isEmpty">
            <monthTest ref="monthTest"></monthTest>
        </template>
        <template v-else-if="reportValue == '5' && !isEmpty">
            <weekTest ref="weekTest"></weekTest>
        </template>
        <template v-else>
            <div class="empty">
                <el-empty description="暂无报告"></el-empty>
            </div>
        </template>
    </div>
</template>
<script>
import participationRate from './appraisalCharts/participationRate.vue'
import incorrectDemonstration from './appraisalCharts/incorrectDemonstration.vue'
import grade_graphics from './appraisalCharts/grade_graphics.vue'
import Short_form from './appraisalCharts/Short_form.vue'
import various_graphics from './appraisalCharts/various_graphics.vue'
import excellent_rates_graphics from './appraisalCharts/excellent_rates_graphics.vue'
import { convertToPdf } from '../../utils/paging_pdf.js';
import GradeAnalysis from './appraisalCharts/GradeAnalysis.vue'
import monthTest from './monthTest.vue'
import weekTest from './weekTest.vue'
import old_School_score from './appraisalCharts/old_School_score.vue'
import new_School_score from './appraisalCharts/new_School_score.vue'
import schoolLevelTable from './schoolLevel_table.vue'
export default {
    //import引入组件才能使用
    components: {
        participationRate,
        incorrectDemonstration,
        grade_graphics,
        Short_form,
        various_graphics,
        excellent_rates_graphics,
        GradeAnalysis,
        monthTest,
        old_School_score,
        new_School_score,
        schoolLevelTable,
        weekTest
    },
    props: ['reportVersion'],
    data() {
        return {
            value: '',
            grade_value: '',
            //-----
            //各年级参与率-时间筛选
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            // table表格传参数
            // 参与情况
            participation_construction: [{
                fieldName: 'grade',
                title: '年级'
            },
            {
                fieldName: 'register',
                title: '注册数'
            },
            {
                fieldName: 'totalNum',
                title: '参与总人数'
            },
            {
                fieldName: 'participationRates',
                title: '参与率'
            },
            ],
            //高度
            Height1: '258',
            row_height1: '21',
            //图例
            participation_graphics: {
                idName: 'participation',
                title: '坐姿错误人数',
                yData: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级'],
                semester1: [],
                semester2: [],
                colordata: [
                    'rgba(250, 193, 141, 1)',
                    'rgba(254, 105, 142,0.8)',
                    'rgba(176, 98, 242,1)',
                    'rgba(228, 152, 221, 0.8)',
                ],
            },
            //书写习惯-坐姿
            sitting_construction: [{
                fieldName: 'grade',
                title: '年级'
            },
            {
                fieldName: 'wrongNum',
                title: '错误坐姿人数'
            },
            {
                fieldName: 'ratios',
                title: '错误比例'
            },
            ],
            sitting_Data: [],
            Height2: '258',
            row_height2: '21',
            //书写习惯-握姿
            hold_construction: [{
                fieldName: 'grade',
                title: '年级'
            },
            {
                fieldName: 'wrongNum',
                title: '错误握姿人数'
            },
            {
                fieldName: 'ratios',
                title: '错误比例'
            },
            ],
            hold_Data: [],
            Height3: '258',
            row_height3: '21',
            //图例
            hold_graphics: {
                idName: ' hold',
                title: '握笔错误人数',
                yData: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级'],
                semester1: [],
                semester2: [],
                colordata: [
                    'rgba(169, 195, 255,1)',
                    'rgba(203, 157, 252, 0.8)',
                    'rgba(221, 223, 45,1)',
                    'rgba(255, 217, 165,0.8)',
                ],

            },
            //握笔错误人数
            hold_ratio_Data: [{
                date: this.table_type == 1 ? '优秀+' : '优秀',
                name: '占比（99.9%）',
                excellent: '99.9%',
                good: '99.9%',
                promote: '99.9%',
            },
            {
                date: this.table_type == 1 ? '优秀' : '良好',
                name: '占比（99.9%）',
                excellent: '99.9%',
                good: '99.9%',
                promote: '99.9%',
            },
            {
                date: this.table_type == 1 ? '良好' : '合格',
                name: '占比（99.9%）',
                excellent: '99.9%',
                good: '99.9%',
                promote: '99.9%',
            },
            {
                date: '待提升',
                name: '占比（99.9%）',
                excellent: '99.9%',
                good: '99.9%',
                promote: '99.9%',
            }
            ],
            //箭头类型
            imgUrl: {
                imgUrl1: require('@/assets/newReport/greent.png'),
                imgUrl2: require('@/assets/newReport/or_r.png'),
                imgUrl3: require('@/assets/newReport/redx.png'),
            },
            performance_mistake_Data: [{
                date: '一年级',
                name: {
                    type: 'imgUrl1',
                    text: 1
                },
                excellent: {
                    type: 'imgUrl1',
                    text: 1
                },
                good: {
                    type: 'imgUrl2',
                    text: 1
                },
                promote: {
                    type: 'imgUrl3',
                    text: 1
                },
            },],
            //
            performance_ratio_Data: [{
                type: '一年级',
                last_term1: {
                    imgtype: 'imgUrl1',
                    value: 10
                },
                next_term1: {
                    imgtype: 'imgUrl1',
                    value: 10
                },
                last_term2: {
                    imgtype: 'imgUrl1',
                    value: 10
                },
                next_term2: {
                    imgtype: 'imgUrl1',
                    value: 10
                },
                last_term3: {
                    imgtype: 'imgUrl1',
                    value: 10
                },
                next_term3: {
                    imgtype: 'imgUrl1',
                    value: 10
                },
                last_term4: {
                    imgtype: 'imgUrl1',
                    value: 10
                },
                next_term4: {
                    imgtype: 'imgUrl1',
                    value: 10
                },
            },],
            //二年级各等级人数及优秀率与上学期对比情况
            excellent_contrast: [{
                fieldName: 'date',
                title: '学期'
            },
            {
                fieldName: 'level4',
                title: this.table_type == 1 ? '优秀+' : '优秀'
            },
            {
                fieldName: 'level3',
                title: this.table_type == 1 ? '优秀' : '良好'
            },
            {
                fieldName: 'level2',
                title: this.table_type == 1 ? '良好' : '合格'
            },
            {
                fieldName: 'level1',
                title: '待提升'
            },
            {
                fieldName: 'level5',
                title: '优秀率'
            },
            ],
            Height5: '185',
            row_height5: '70px',
            termList: [], // 学期列表
            termValue: '', // 当前学期绑定的值
            currentTerm: '',
            reportList: [
                // {
                //     value: 1,
                //     label: '前测报告'
                // },
                // {
                //     value: 2,
                //     label: '后测报告'
                // },
                // {
                //     value: 3,
                //     label: '月测报告'
                // },
                // {
                //     value: 5,
                //     label: '周测报告'
                // }
                {
                    value: 1,
                    label: '前测报告'
                },
                {
                    value: 2,
                    label: '后测报告'
                },
                {
                    value: 4,
                    label: '月测报告'
                },
                {
                    value: 5,
                    label: '周测报告'
                }
            ], // 报告类型
            reportValue: 1, // 当前报告类型(前测/后测)
            correctReportType: 1,//校正后报告类型id
            currentReportType: '前测报告',
            date: [],
            joinData: [],
            joinRate_graphics: {
                idName: 'participation',
                title: '坐姿错误人数',
                yData: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级'],
                semester1: [],
                semester2: [],
                colordata: [
                    'rgba(250, 193, 141, 1)',
                    'rgba(254, 105, 142,0.8)',
                    'rgba(176, 98, 242,1)',
                    'rgba(228, 152, 221, 0.8)',
                ],
            },
            stuCount: null,
            pageCount: '',
            sitCount: 0,
            sitRate: 0,
            holdCount: 0,
            holdRate: 0,
            holdData: [],
            levelData: [],
            seriesData: [0, 0, 0, 0],
            comment4: null,
            various_graphics_data1: [0, 0, 0, 0],
            various_graphics_data2: [0, 0, 0, 0, 0, 0, 0, 0],
            //雷达图
            variousData1: [{ name: '章法占格', max: 100 },
            { name: '作品完整度', max: 100 },
            { name: '笔画形态', max: 100 },
            { name: '结构组合', max: 100 }],
            variousData2: [
                { name: '居中占位', max: 100 },
                { name: '占格大小', max: 100 },
                { name: '字形重心', max: 100 },
                { name: '组合关系', max: 100 },
                { name: '收放关系', max: 100 },
                { name: '多画等距', max: 100 },
                { name: '笔画关系', max: 100 },
                { name: '作品完整度', max: 100 },
            ],
            tablaData_school: [],
            tableData_compare: [],
            comment5: null,
            comment6: null,
            grade_options: [],
            gradeType_options: {
                '1': '一年级',
                '2': '二年级',
                '3': '三年级',
                '4': '四年级',
                '5': '五年级',
                '6': '六年级',
            },
            currentGrade: '',
            schoolName: '',
            sitimg: true,
            workList2: [require('../../assets/newReport/wobi2.png'), require('../../assets/newReport/wobi1.png')],
            isEmpty: false,
            // 年级分析平铺
            Gradetype: {
                1: '一年级',
                2: '二年级',
                3: '三年级',
                4: '四年级',
                5: '五年级',
                6: '六年级',
            },
            Gradelist: [],
            DownLoadType: true,
            exportReport_DownLoadType: true,
            reportType: 1, // 报告类型:4前测 5后测
            reportCode: 'LZ3-C-00', // 报告code:LZ3-C-00前测 LZ3-C-01后测
            comment_one: true,
            comment_two: true,
            comment_three: true,
            //月测
            monthList: [],
            monthValue: '',
            monthName: '',
            monthId: '',
            // 周测
            weekList: [],
            weekValue: '',
            weekName: '',

            schoolIdArr: ['181166', '1176', '181095', '181165', '1172', '181164', '1175', '181097', '1154'],
            table_type: 2,
            newComment: [],
            comments4: {
                'center_pos': {
                    '优秀': '字占位精准，整字平衡美观。',
                    '良好': '字布局合理，基本做到了占位居中。',
                    '合格': '字略有偏移，要注意第一笔占位，让字整体居中一些。',
                    '待提升': '字严重偏移，要养成居中书写的好习惯，特别是注意第一笔的位置。',
                },
                'grid_size': {
                    '优秀': '字大小完全一致，比例协调。',
                    '良好': '字基本做到大小合理，可注意书写一致性。',
                    '合格': '字过大或过小，要检查握笔是否过高或过低。',
                    '待提升': '字缩放严重，要留意各部件位置，以及部件的高矮和宽窄。',
                },
                'glyph_grav': {
                    '优秀': '字重心挺拔有力，有坚定之感。',
                    '良好': '大部分单字基本做到字形重心稳定平衡。',
                    '合格': '字略有歪斜，要注意笔画方向要做到横平竖直，上下对正，左右对齐，另外字的底部也要保持平稳。',
                    '待提升': '字严重失衡，要注意调整正确的坐姿和握笔姿势，养成先观察后书写的习惯。笔画要做到横平竖直。每写一笔都要参照上一笔来写。',
                },
                'rewind_rel': {
                    '优秀': '字的笔画有长有短。能够收放自如，字形舒展大方。',
                    '良好': '大部分单字能够做到主体紧凑，但作为长笔画的主笔要记得舒展写长。',
                    '合格': '字主体紧凑，要注意字的笔画是有长短区分的，观察好字的长笔画，记得把它们伸展写长。',
                    '待提升': '字收放失度，要养成先观察后书写的好习惯，注意笔画有长有短。笔画间距越准确，字也会美观。',
                },
                'multi_dist': {
                    '优秀': '笔画长短、收放、交接及布白关系准确合理，空间疏密有致。',
                    '良好': '大部分单字同向（横向、纵向和斜向）笔画组间距可做到均匀等距。',
                    '合格': '字布白不均，要保持笔画交接关系准确，争取做到笔画间距均匀。',
                    '待提升': '字空间凌乱，在临摹时坚持“先关系后长短”的原则，提高空间布白意识。',
                },
                'stroke_rel': {
                    '优秀': '笔画位置精准对齐，长度恰当，保持字形的均衡和美感',
                    '良好': '大部分笔画位置准确，长度基本适宜，可能在某些笔画上略显不足或过长。',
                    '合格': '笔画位置大体准确，但可能存在一些偏差，部分笔画长度需要调整，以更好地符合字形规范。',
                    '待提升': '笔画位置存在较大偏差，长度控制不足，需要通过练习来掌握正确的位置和比例关系。',
                },
                'combo_rel': {
                    '优秀': '字能准确处理避让穿插关系，做到穿插合理，字形舒展。继续加油。',
                    '良好': '大部分单字可较好处理部件之间避让穿插的紧密关系，但要注意笔画之间的位置要准确一些。',
                    '合格': '字字形松散，笔画之间要紧凑，注意笔画相互的穿插，部件之间要联系紧密。',
                    '待提升': '字比例失度，要注意观察部件外形的大小和形状，确定好比例关系再书写。',
                },
                'integrity': {
                    '优秀': '可在规定时间内全部完成。',
                    '良好': '完成度较高。',
                    '合格': '部分内容不能正确完成。',
                    '待提升': '大部分内容不能正确完成。',
                },
            }
        }
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        //获取学年学期列表 
        async getTermList() {
            this.$Api.DataScreen.queryTermList()
                .then(async res => {
                    this.termList = res.data;
                    if (this.$route.query.fromType == 'multiRole') {
                        if (this.$route.query.date && this.$route.query.fromType == 'multiRole') {
                            //大屏跳转带参数
                            //判断报告类型
                            // let testTypedata = this.$route.query.testType == 4 ? '3' : this.$route.query.testType;
                            this.reportValue = Number(this.$route.query.testType);
                            //判断学期
                            const index = this.termList.findIndex(item => item.key == Number(this.$route.query.date));
                            this.currentTerm = this.termList[index].termName;
                            this.termValue = this.termList[index].key;
                        }
                    } else {
                        this.termValue = this.termList[0].key;
                        this.currentTerm = this.termList[0].termName;
                    }
                    try {
                        await this.reportTypeChange();
                        await this.getIsNewDIm();
                        await this.switchGetData();
                    } catch {
                        console.error('异步调用失败:', err);
                    }

                })
                .catch(err => {
                    // console.log('err:', err)
                })
        },
        async getIsNewDIm() {
            // console.log('this.correctReportType', this.correctReportType);

            let data = {
                termKey: this.termValue,
                schoolId: this.$route.query.schoolId,
                examType: this.correctReportType
                // examType: 1
            }
            let resData = await this.$Api.DataScreen.getIsNewDIm(data);
            this.table_type = resData.data;
            console.log('获取当前报告版本', this.table_type);

        },
        // 修改学期
        async termChange(val) {
            const index = this.termList.findIndex(item => item.key == val);
            this.currentTerm = this.termList[index].termName;
            this.wipeData();
            try {
                await this.reportTypeChange();
                await this.getIsNewDIm();
                await this.switchGetData();
            } catch {
                console.error('异步调用失败:', err);
            }

        },
        // 修改报告类型
        async reportChange(val) {
            const index = this.reportList.findIndex(item => item.value == val);
            this.currentReportType = this.reportList[index].label;
            try {
                await this.reportTypeChange();
                await this.getIsNewDIm();
                await this.switchGetData();
            } catch {
                console.error('异步调用失败:', err);
            }

        },
        //请求接口集合
        async integrationApi() {
            //参与情况
            // 学生总数
            const joinInfoResult = await this.joinInfo();
            //判断学生总数为空为0 不在继续请求
            if (!joinInfoResult) return;
            // //各年级参与率
            this.joinRate();
            // // 书写习惯-坐姿
            this.sitPosition();
            // // 坐姿错误人数
            this.sitPositionChat();
            // // 书写习惯-握姿
            this.holdAPenPosture();
            // // 握姿错误人数
            this.holdAPenPostureChart();
            // //查年级
            this.getTakePartGradeList();
            if (this.table_type == 1) {
                // 全校各等级学员占比-旧版
                this.studentLvRatioCompare();
                //各维度全校成绩对比-旧版
                this.oneDScoreCompare();
                //各维度全校成绩对比-与上一学期测评对比-旧版
                this.oneDScoreCompareDiff();
            } else {
                // 全校各等级学员占比-新版
                this.studentLvRatioCompareV2();
                //各维度全校成绩对比-与上一学期测评对比-新版
                this.oneDScoreCompareDiff2();
            }
        },
        // 参与情况
        joinInfo() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                examType: this.correctReportType,
                // testType: this.modify(this.correctReportType),
                // testTypeCode: this.reportCode
            };
            let Total_students = this.$Api.Report.joinInfo(data)
                .then(res => {
                    let total = 0;
                    res.data.dataList.map(item => {
                        item.participationRates = item.participationRate + '%';
                        total += item.totalNum
                    })
                    this.isEmpty = !!total;
                    this.joinData = res.data.dataList;
                    this.stuCount = res.data.k1;
                    this.pageCount = res.data.k2;
                    if (this.joinData.length > 0) {
                        let num = this.joinData[0].participationRate;
                        for (let i = 1; i < this.joinData.length; i++) {
                            if (this.joinData[i].participationRate !== num) {
                                this.comment_one = false;
                                // break
                            }
                        }
                    };
                    return this.stuCount;
                })
                .catch(err => {
                    console.log(err)
                });
            return Total_students;
        },
        //各年级参与率
        joinRate() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                // testType: this.modify(this.correctReportType),
                examType: this.correctReportType,
                // testTypeCode: this.reportCode
            }
            this.$Api.Report.joinRate(data)
                .then(res => {
                    this.joinRate_graphics['semester1'] = res.data.thisTermList;
                    this.joinRate_graphics['semester2'] = res.data.lastTermList;
                })
                .catch(err => {
                    // console.log(err)
                })
        },
        // 书写习惯-坐姿
        sitPosition() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                // testType: this.modify(this.correctReportType),
                examType: this.correctReportType,
                // testTypeCode: this.reportCode
            }
            this.$Api.Report.sitPosition(data)
                .then(res => {
                    this.sitCount = res.data.k1;
                    this.sitRate = res.data.k2;
                    res.data.dataList.map(item => {
                        item.ratios = item.ratio + '%'
                    })
                    this.sitting_Data = res.data.dataList;
                    if (this.sitting_Data.length > 0) {
                        let num = this.sitting_Data[0].ratio;
                        for (let i = 1; i < this.sitting_Data.length; i++) {
                            if (this.sitting_Data[i].ratio !== num) {
                                this.comment_two = false;
                                break
                            }
                        }
                    }
                })
                .catch(err => {
                    // console.log(err)
                })
        },
        // 坐姿错误人数
        sitPositionChat() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                // testType: this.modify(this.correctReportType),
                examType: this.correctReportType,
                // testTypeCode: this.reportCode
            }
            this.$Api.Report.sitPositionChat(data)
                .then(res => {
                    this.participation_graphics.semester1 = res.data.thisDataTerm;
                    if (this.termValue == 20231) {
                        this.participation_graphics.semester1.map(item => {
                            this.participation_graphics.semester2.push(0)
                        })
                    } else {
                        this.participation_graphics.semester2 = res.data.lastDataTerm
                    }

                })
                .catch(err => {
                    // console.log(err)
                })
        },
        // 书写习惯-握姿
        holdAPenPosture() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                // testType: this.modify(this.correctReportType),
                examType: this.correctReportType,
                // testTypeCode: this.reportCode
            }
            this.$Api.Report.holdAPenPosture(data)
                .then(res => {
                    this.holdCount = res.data.k1;
                    this.holdRate = res.data.k2;
                    res.data.dataList.map(item => {
                        item.ratios = item.ratio + '%'
                    })
                    this.hold_Data = res.data.dataList;
                    if (this.hold_Data.length > 0) {
                        let num = this.hold_Data[0].ratio;
                        for (let i = 1; i < this.hold_Data.length; i++) {
                            if (this.hold_Data[i].ratio !== num) {
                                this.comment_three = false;
                                break
                            }
                        }
                    }
                })
                .catch(err => {
                    // console.log(err)
                })
        },
        // 握姿错误人数
        holdAPenPostureChart() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                // testType: this.modify(this.correctReportType),
                examType: this.correctReportType,
                // testTypeCode: this.reportCode
            }
            this.$Api.Report.holdAPenPostureChart(data)
                .then(res => {
                    this.hold_graphics.semester1 = res.data.thisDataTerm;
                    if (this.termValue == 20231) {
                        this.hold_graphics.semester2.semester1.map(item => {
                            this.hold_graphics.semester2.semester2.push(0)
                        })
                    } else {
                        this.hold_graphics.semester2 = res.data.lastDataTerm
                    }
                })
                .catch(err => {
                    // console.log(err)
                })
        },
        // 全校各等级学员占比
        studentLvRatioCompare() {
            this.levelData = [];
            this.holdData = [];
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                examType: this.correctReportType,
                // testType: this.modify(this.correctReportType),
                // testTypeCode: this.reportCode
            }
            this.$Api.Report.studentLvRatioCompare(data)
                .then(res => {
                    // 上下学期比较 1高于 2相等 3低于
                    res.data.compare[0].level4_compare = this.compare_icon(res.data.compare[0].level1, res.data.compare[1].level1);
                    res.data.compare[0].level3_compare = this.compare_icon(res.data.compare[0].level2, res.data.compare[1].level2);
                    res.data.compare[0].level2_compare = this.compare_icon(res.data.compare[0].level3, res.data.compare[1].level3);
                    res.data.compare[0].level1_compare = this.compare_icon(res.data.compare[0].level4, res.data.compare[1].level4);

                    // 握笔错误表格
                    res.data.compare.map((item, index) => {
                        const term = index === 0 ? '本学期' : '上学期';
                        const obj = {
                            term,
                            level1: this.nullData(item.level4, term),
                            level2: this.nullData(item.level3, term),
                            level3: this.nullData(item.level2, term),
                            level4: this.nullData(item.level1, term),
                        };
                        if (index === 0) {
                            obj.level1_compare = item.level1_compare;
                            obj.level2_compare = item.level2_compare;
                            obj.level3_compare = item.level3_compare;
                            obj.level4_compare = item.level4_compare;
                        }
                        this.holdData.push(obj);
                    })
                    const levelTypes = {
                        0: this.table_type == 1 ? '优秀+' : '优秀',
                        1: this.table_type == 1 ? "优秀" : '良好',
                        2: this.table_type == 1 ? "良好" : '合格',
                        3: "待提升"
                    }
                    // const arr = [];
                    res.data.resultList.map((item, index) => {
                        let obj = {
                            level: levelTypes[index],
                            layout_1: item.layout_1, // 卷面整洁
                            layout_2: item.layout_2, // 占格占位
                            layout_3: item.layout_3, // 字形大小
                            structure_1: item.structure_1, // 字形重心
                            structure_2: item.structure_2, // 收放关系
                            structure_3: item.structure_3, // 笔画关系
                            structure_4: item.structure_4, // 组合关系
                            form_1: item.form_1, // 方向
                            form_2: item.form_2, // 笔性
                            form_3: item.form_3, // 粗细
                            complete_1: item.complete_1, // 试卷完成度
                            totalRatio: item.totalRatio, // 综合占比
                        }
                        this.levelData.push(obj);
                    })
                    this.seriesData = [res.data.compare[0].level4, res.data.compare[0].level3, res.data.compare[0].level2, res.data.compare[0].level1]
                    this.comment4 = res.data;
                })
                .catch(err => {
                    // console.log(err)
                })



        },
        studentLvRatioCompareV2() {
            this.levelData = [];
            this.holdData = [];
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                examType: this.correctReportType,
                // testTypeCode: this.reportCode
            }
            this.$Api.Report.studentLvRatioCompareV2(data)
                .then(res => {
                    // 上下学期比较 1高于 2相等 3低于
                    res.data.compare[0].level4_compare = this.compare_icon(res.data.compare[0].level1, res.data.compare[1].level1);
                    res.data.compare[0].level3_compare = this.compare_icon(res.data.compare[0].level2, res.data.compare[1].level2);
                    res.data.compare[0].level2_compare = this.compare_icon(res.data.compare[0].level3, res.data.compare[1].level3);
                    res.data.compare[0].level1_compare = this.compare_icon(res.data.compare[0].level4, res.data.compare[1].level4);
                    // 握笔错误表格
                    res.data.compare.map((item, index) => {
                        const term = index === 0 ? '本学期' : '上学期';
                        const obj = {
                            term,
                            level1: this.nullData(item.level4, term),
                            level2: this.nullData(item.level3, term),
                            level3: this.nullData(item.level2, term),
                            level4: this.nullData(item.level1, term),
                        };
                        if (index === 0) {
                            obj.level1_compare = item.level1_compare;
                            obj.level2_compare = item.level2_compare;
                            obj.level3_compare = item.level3_compare;
                            obj.level4_compare = item.level4_compare
                        }
                        this.holdData.push(obj);
                    })
                    this.seriesData = [res.data.compare[0].level4, res.data.compare[0].level3, res.data.compare[0].level2, res.data.compare[0].level1]
                    this.comment4 = res.data;
                    this.newComment = res.data.resultList;
                })
                .catch(err => {
                    // console.log(err)
                })
        },
        //判断空数据（本班单项成绩等级占比）
        formatCleanliness(row, column, cellValue, index) {
            return cellValue != 0 && !cellValue ? '0%' : cellValue + '%';
        },
        // 一级维度全校成绩对比
        oneDScoreCompare() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                examType: this.correctReportType,
                // testType: this.modify(this.correctReportType),
                // testTypeCode: this.reportCode
            }
            this.$Api.Report.oneDScoreCompare(data)
                .then(res => {
                    let obj = res.data.list.find(item => item.indexRank == '全校');
                    // 各年级一级维度与全校维度对比 1高于 2相等 3低于
                    res.data.list.map(item => {
                        if (item.indexRank != '全校') {
                            item.parentId1_compare = item.parentId1 > obj.parentId1 ? 1 : item.parentId1 == obj.parentId1 ? 2 : 3;
                            item.parentId2_compare = item.parentId2 > obj.parentId2 ? 1 : item.parentId2 == obj.parentId2 ? 2 : 3;
                            item.parentId3_compare = item.parentId3 > obj.parentId3 ? 1 : item.parentId3 == obj.parentId3 ? 2 : 3;
                            item.parentId4_compare = item.parentId4 > obj.parentId4 ? 1 : item.parentId4 == obj.parentId4 ? 2 : 3;
                        }
                    })
                    this.various_graphics_data1 = [res.data.theWholeSchool.parentId1.toFixed(2), res.data.theWholeSchool.parentId4.toFixed(2), res.data.theWholeSchool.parentId3.toFixed(2), res.data.theWholeSchool.parentId2.toFixed(2)];
                    this.tablaData_school = res.data.list;
                })
                .catch(err => {
                    // console.log('一级维度全校成绩err:', err)
                })
        },
        // 与上一学期测评对比  <旧版>
        oneDScoreCompareDiff() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                examType: this.correctReportType,
                // testType: this.modify(this.correctReportType),
                // testTypeCode: this.reportCode
            }
            this.$Api.Report.oneDScoreCompareDiff(data)
                .then(res => {
                    // 各年级本学期与上学期对比 1高于 2相等 3低于
                    res.data.resultDiffList.map(item => {
                        item.parentId1_compare = item.parentId1ben > item.parentId1last ? 1 : item.parentId1ben == item.parentId1last ? 2 : 3;
                        item.parentId2_compare = item.parentId2ben > item.parentId2last ? 1 : item.parentId2ben == item.parentId2last ? 2 : 3;
                        item.parentId3_compare = item.parentId3ben > item.parentId3last ? 1 : item.parentId3ben == item.parentId3last ? 2 : 3;
                        item.parentId4_compare = item.parentId4ben > item.parentId4last ? 1 : item.parentId4ben == item.parentId4last ? 2 : 3;
                    })
                    this.tableData_compare = res.data.resultDiffList;
                    this.comment5 = res.data;
                })
                .catch(err => {
                    // console.log('与上一学期测评对比err:', err)
                })
        },
        // 与上一学期测评对比 <新版>
        oneDScoreCompareDiff2() {
            // let examType = this.reportType == 4 ? 1 : 2;
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                examType: this.correctReportType,
            }
            this.$Api.Report.oneDScoreCompareDiffV2(data)
                .then(res => {
                    //1 
                    let obj = res.data.resultList.find(item => item.indexRank == '全校');
                    res.data.resultList.map(item => {
                        if (item.indexRank != '全校') {
                            item.centerPosAvg_compare = item.centerPosAvg > obj.centerPosAvg ? 1 : item.centerPosAvg == obj.centerPosAvg ? 2 : 3;
                            item.gridSizeAvg_compare = item.gridSizeAvg > obj.gridSizeAvg ? 1 : item.gridSizeAvg == obj.gridSizeAvg ? 2 : 3;
                            item.glyphGravAvg_compare = item.glyphGravAvg > obj.glyphGravAvg ? 1 : item.glyphGravAvg == obj.glyphGravAvg ? 2 : 3;
                            item.comboRelAvg_compare = item.comboRelAvg > obj.comboRelAvg ? 1 : item.comboRelAvg == obj.comboRelAvg ? 2 : 3;
                            item.rewindRelAvg_compare = item.rewindRelAvg > obj.rewindRelAvg ? 1 : item.rewindRelAvg == obj.rewindRelAvg ? 2 : 3;
                            item.multiDistAvg_compare = item.multiDistAvg > obj.multiDistAvg ? 1 : item.multiDistAvg == obj.multiDistAvg ? 2 : 3;
                            item.strokeRelAvg_compare = item.strokeRelAvg > obj.strokeRelAvg ? 1 : item.strokeRelAvg == obj.strokeRelAvg ? 2 : 3;
                            item.integrityAvg_compare = item.integrityAvg > obj.integrityAvg ? 1 : item.integrityAvg == obj.integrityAvg ? 2 : 3;
                        }
                    })
                    this.tablaData_school = res.data.resultList;
                    this.various_graphics_data2 = [Number(obj.centerPosAvg).toFixed(2), Number(obj.gridSizeAvg).toFixed(2), Number(obj.glyphGravAvg).toFixed(2), Number(obj.comboRelAvg).toFixed(2), Number(obj.rewindRelAvg).toFixed(2), Number(obj.multiDistAvg).toFixed(2), Number(obj.strokeRelAvg).toFixed(2), Number(obj.integrityAvg).toFixed(2),];
                    // 各年级本学期与上学期对比 1高于 2相等 3低于
                    res.data.resultDiffList.map(item => {
                        item.centerPosAvg_compare = item.centerPosAvgBen > item.centerPosAvgLast ? 1 : item.centerPosAvgBen == item.centerPosAvgLast ? 2 : 3;
                        item.gridSizeAvg_compare = item.gridSizeAvgBen > item.gridSizeAvgLast ? 1 : item.gridSizeAvgBen == item.gridSizeAvgLast ? 2 : 3;
                        item.glyphGravAvg_compare = item.glyphGravAvgBen > item.glyphGravAvgLast ? 1 : item.glyphGravAvgBen == item.glyphGravAvgLast ? 2 : 3;
                        item.comboRelAvg_compare = item.comboRelAvgBen > item.comboRelAvgLast ? 1 : item.comboRelAvgBen == item.comboRelAvgLast ? 2 : 3;
                        item.rewindRelAvg_compare = item.rewindRelAvgBen > item.rewindRelAvgLast ? 1 : item.rewindRelAvgBen == item.rewindRelAvgLast ? 2 : 3;
                        item.multiDistAvg_compare = item.multiDistAvgBen > item.multiDistAvgLast ? 1 : item.multiDistAvgBen == item.multiDistAvgLast ? 2 : 3;
                        item.strokeRelAvg_compare = item.strokeRelAvgBen > item.strokeRelAvgLast ? 1 : item.strokeRelAvgBen == item.strokeRelAvgLast ? 2 : 3;
                        item.integrityAvg_compare = item.integrityAvgBen > item.integrityAvgLast ? 1 : item.integrityAvgBen == item.integrityAvgLast ? 2 : 3;
                    })
                    this.tableData_compare = res.data.resultDiffList;
                    this.comment5 = res.data;
                })
                .catch(err => {
                    // console.log('与上一学期测评对比err:', err)
                })
        },
        // 切换年级
        changeGtade(value) {
            this.grade_value = value;
            this.currentGrade = this.grade_options.find(item => item.value == value).label;
        },
        // 换一组看看(书写习惯-坐姿)
        toggle() {
            this.sitimg = !this.sitimg
        },
        // 对比最高和最低
        compare(data, rate) {
            // 找出最高和最低的参与率
            const maxParticipation = Math.max(...data.map(item => item[rate]));
            const minParticipation = Math.min(...data.map(item => item[rate]));
            // 找出最高和最低的年级
            const maxGrade = data.find(item => item[rate] === maxParticipation).grade;
            const minGrade = data.find(item => item[rate] === minParticipation).grade;
            return {
                maxRate: maxGrade,
                minRate: minGrade
            }
        },
        // 结论5 计算各维度成绩前两名
        computedComment5(list, name) {
            list.sort((a, b) => b[name] - a[name]);
            try {
                return `${list[0].indexRank}`

            } catch (err) {
                // console.log('计算结论5出报错:', err)
            }

        },
        // 结论5 计算各维度与上学期对比
        computedLastTerm(list, name) {
            let className = [];
            let comment = '';
            list.map(item => {
                if (item[`${name}ben`] > item[`${name}last`]) {
                    className.push(item.indexRank)
                }
            })
            if (className.length == list.length) {
                comment = '各年级都有所提升'
            } else {
                comment = `${className.join('、')}相较上个学期有所提升`
            }
            return comment
        },
        // 导出报告
        exportReport() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                examType: this.correctReportType,
                // testType: this.correctReportType,
                // testTypeCode: this.reportCode
            }
            this.exportReport_DownLoadType = false;
            setTimeout(() => {
                // 10秒后（10000毫秒）将DownLoadType重置为false
                this.exportReport_DownLoadType = true;
            }, 3000);
            this.$Api.Report.exportTest(data)
                .then(res => {
                    const blob = new Blob([res], {
                        type: 'application/octet-stream'
                    });
                    const url = window.URL.createObjectURL(blob);
                    // 以动态创建a标签进行下载
                    const a = document.createElement('a');
                    const fileName = this.schoolName + this.currentReportType + '数据(' + this.currentTerm + ')';
                    a.href = url;
                    a.download = fileName + '.xlsx';
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(err => {
                    // console.log('导出失败:', err)
                })
        },
        // 导出报告周测
        exportReportweek() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.weekValue,
                examType: 5
            }
            this.exportReport_DownLoadType = false;
            setTimeout(() => {
                this.exportReport_DownLoadType = true;
            }, 3000);
            this.$Api.Report.exportTestmonthV2(data)
                .then(res => {
                    const blob = new Blob([res], {
                        type: 'application/octet-stream'
                    });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    const fileName = this.schoolName + this.currentReportType + '数据(' + this.currentTerm + ')';
                    a.href = url;
                    a.download = fileName + '.xlsx';
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(err => {
                    // console.log('导出失败:', err)
                })
        },
        // 导出报告月测
        exportReportmonth() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.monthValue,
                examType: this.correctReportType,
            }
            this.exportReport_DownLoadType = false;
            setTimeout(() => {
                // 10秒后（10000毫秒）将DownLoadType重置为false
                this.exportReport_DownLoadType = true;
            }, 3000);
            if (this.table_type == 1) {
                this.$Api.Report.exportTestmonth(data)
                    .then(res => {
                        const blob = new Blob([res], {
                            type: 'application/octet-stream'
                        });
                        const url = window.URL.createObjectURL(blob);
                        // 以动态创建a标签进行下载
                        const a = document.createElement('a');
                        const fileName = this.schoolName + this.currentReportType + '数据(' + this.currentTerm + ')';
                        a.href = url;
                        a.download = fileName + '.xlsx';
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch(err => {
                        // console.log('导出失败:', err)
                    })
            } else {
                this.$Api.Report.exportTestmonthV2(data)
                    .then(res => {
                        const blob = new Blob([res], {
                            type: 'application/octet-stream'
                        });
                        const url = window.URL.createObjectURL(blob);
                        // 以动态创建a标签进行下载
                        const a = document.createElement('a');
                        const fileName = this.schoolName + this.currentReportType + '数据(' + this.currentTerm + ')';
                        a.href = url;
                        a.download = fileName + '.xlsx';
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch(err => {
                        // console.log('导出失败:', err)
                    })
            }

        },
        //查年级
        getTakePartGradeList() {
            this.grade_options = [];
            this.Gradelist = [];
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                examType: this.correctReportType,
                // testType: this.modify(this.correctReportType),
                // testTypeCode: this.reportCode
            }
            this.$Api.Report.getTakePartGradeList(data).then(res => {
                this.Gradelist = res.data;
            }).catch(err => {
                // console.log('导出失败:', err)
            })
        },
        //分页
        handleDownLoad() {
            this.DownLoadType = false;
            setTimeout(() => {
                // 10秒后（10000毫秒）将DownLoadType重置为false
                this.DownLoadType = true;
            }, 13000);
            let domlist = [
                { content: document.getElementById('type_title') },
                { content: document.getElementById('pdf1') },
                { content: document.getElementById('pdf2') },
                { content: document.getElementById('pdf3') },
                { content: document.getElementById('pdf4') },
                { content: document.getElementById('pdf5') },
                { content: document.getElementById('pdf6') },
                { content: document.getElementById('Child2') },
            ]
            let myChildlist = [];
            for (let i = 0; i < this.Gradelist.length; i++) {
                myChildlist.push(
                    { content: document.getElementById('Child_t' + this.Gradelist[i]) },
                )
                myChildlist.push(
                    { content: document.getElementById('Child_b' + this.Gradelist[i]) },
                )
                myChildlist.push(
                    { content: document.getElementById('Child_i' + this.Gradelist[i]) },
                )
            }
            let name = '';
            let mergedArray = [...domlist, ...myChildlist];
            this.reportList.forEach(item => {

                if (item.value == this.reportValue) {
                    name = item.label
                }
            })
            const fileName = this.schoolName + name + '(' + this.currentTerm + ')';
            convertToPdf(fileName, mergedArray)
        },
        handleDownLoadmonth() {
            this.DownLoadType = false;
            setTimeout(() => {
                // 10秒后（10000毫秒）将DownLoadType重置为false
                this.DownLoadType = true;
            }, 13000);
            let Gradelist = this.$refs['monthTest'].Gradelist;
            let domlist = [
                { content: document.getElementById('type_title') },
                { content: document.getElementById('pdf1') },
                { content: document.getElementById('pdf5') },
                { content: document.getElementById('pdf6') },
            ]
            let myChildlist = [];
            for (let i = 0; i < Gradelist.length; i++) {
                myChildlist.push(
                    { content: document.getElementById('Child_t' + Gradelist[i]) },
                )
                myChildlist.push(
                    { content: document.getElementById('Child_b' + Gradelist[i]) },
                )
                myChildlist.push(
                    { content: document.getElementById('Child_z' + Gradelist[i]) },
                )
                myChildlist.push(
                    { content: document.getElementById('Child_i' + Gradelist[i]) },
                )
            }
            let name = '';
            let mergedArray = [...domlist, ...myChildlist];
            this.reportList.forEach(item => {

                if (item.value == this.reportValue) {
                    name = item.label
                }
            })
            let yuename = '';
            this.monthList.forEach(item => {

                if (item.id == this.monthValue) {
                    yuename = item.name
                }
            });
            const fileName = this.schoolName + name + '(' + this.currentTerm + yuename + ')';
            convertToPdf(fileName, mergedArray)
        },
        handleDownLoadweek() {
            this.DownLoadType = false;
            setTimeout(() => {
                // 10秒后（10000毫秒）将DownLoadType重置为false
                this.DownLoadType = true;
            }, 13000);
            let Gradelist = this.$refs['weekTest'].Gradelist;
            let domlist = [
                { content: document.getElementById('type_title') },
                { content: document.getElementById('pdf1') },
                { content: document.getElementById('pdf5') },
                { content: document.getElementById('pdf6') },
            ]
            let myChildlist = [];
            for (let i = 0; i < Gradelist.length; i++) {
                myChildlist.push(
                    { content: document.getElementById('Child_t' + Gradelist[i]) },
                )
                myChildlist.push(
                    { content: document.getElementById('Child_b' + Gradelist[i]) },
                )
                myChildlist.push(
                    { content: document.getElementById('Child_z' + Gradelist[i]) },
                )
                myChildlist.push(
                    { content: document.getElementById('Child_i' + Gradelist[i]) },
                )
            }
            let name = '';
            let mergedArray = [...domlist, ...myChildlist];
            this.reportList.forEach(item => {

                if (item.value == this.reportValue) {
                    name = item.label
                }
            })
            let yuename = '';
            this.monthList.forEach(item => {

                if (item.id == this.monthValue) {
                    yuename = item.name
                }
            });
            const fileName = this.schoolName + name + '(' + this.currentTerm + yuename + ')';
            convertToPdf(fileName, mergedArray)
        },
        //月测列表详情
        getMonthTestList() {
            this.monthList = [];
            this.monthValue = '';
            let that = this;
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                examType: 4,
            }
            this.$Api.Report.getparticipationMonth(data)
                .then(res => {
                    if (res.data.length != 0) {
                        this.monthList = res.data;

                        this.monthValue = this.monthList[0].id;
                        this.monthName = this.monthList[0].name;
                        this.isEmpty = true;
                        that.$nextTick(() => {
                            that.$refs.monthTest.aggregate(that.termValue, that.monthValue, that.monthName, that.correctReportType);
                        });

                    } else {
                        this.isEmpty = false;
                    }
                }).catch(err => {
                    // console.log('err', err)
                })
        },
        //周测列表详情
        getweekTestList() {
            this.weekList = [];
            this.weekValue = '';
            let that = this;
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                examType: 5,
            }
            this.$Api.Report.getparticipationWeek(data)
                .then(res => {
                    if (res.data.length != 0) {
                        this.weekList = res.data;
                        this.weekValue = this.weekList[0].id;
                        this.weekName = this.weekList[0].name;
                        that.$nextTick(() => {
                            that.$refs.weekTest.aggregate(that.termValue, that.weekValue, that.weekName, that.correctReportType);
                        });
                        this.isEmpty = false;
                    } else {
                        this.isEmpty = true;
                    }
                }).catch(err => {
                    console.log('err', err)
                })
        },
        monthChange(val) {
            // this.getIsNewDIm();
            this.monthValue = val;
            this.monthName = this.monthList.find(item => {
                return item.id == val;
            }).name;
            this.$refs.monthTest.aggregate(this.termValue, this.monthValue, this.monthName, this.correctReportType);
        },
        weekChange(val) {
            // this.getIsNewDIm();
            this.weekValue = val;
            this.weekName = this.weekList.find(item => {
                return item.id == val;
            }).name;
            this.$refs.weekTest.aggregate(this.termValue, this.weekValue, this.weekName, this.correctReportType);
        },
        hide_school() {
            let type = this.schoolIdArr.includes(this.$route.query.schoolId);
            if (this.schoolIdArr.includes(this.$route.query.schoolId) && this.termValue == '20232') {
                type = true;
            } else {
                type = false;
            }
            return type;
        },
        computedComment4(itemname) {
            let weidu = {
                'center_pos': '居中占位',
                'grid_size': '占格大小',
                'glyph_grav': '字形重心',
                'rewind_rel': '收放关系',
                'multi_dist': '多画等距',
                'stroke_rel': '笔画关系',
                'combo_rel': '组合关系',
                'integrity': '作品完整度'
            }
            let arr = [];
            this.newComment.map(item => {
                let obj = {
                    name: weidu[itemname],
                }
                if (item.level == 4) {
                    obj.value = item[itemname];
                    obj.levelname = '优秀'
                }
                if (item.level == 3) {
                    obj.value = item[itemname];
                    obj.levelname = '良好'
                }
                if (item.level == 2) {
                    obj.value = item[itemname];
                    obj.levelname = '合格'
                }
                if (item.level == 1) {
                    obj.value = item[itemname];
                    obj.levelname = '待提升'
                }
                arr.push(obj);
            })
            const maxLevelObj = arr.reduce((max, obj) => (obj.value > max.value ? obj : max), arr[0]);
            if (maxLevelObj.value > 80) {
                maxLevelObj.rate = '大部分'
            }
            if (maxLevelObj.value > 50 && maxLevelObj.value <= 80) {
                maxLevelObj.rate = '一半以上'
            }
            if (maxLevelObj.value == 50) {
                maxLevelObj.rate = '一半'
            }
            if (maxLevelObj.value > 30 && maxLevelObj.value < 50) {
                maxLevelObj.rate = '近一半'
            }
            if (maxLevelObj.value <= 30) {
                maxLevelObj.rate = '少部分'
            }
            return maxLevelObj
        },
        // 判断空数据
        nullData(value, term) {
            return !value && value != 0 ? '/' : value + '%';
        },
        //返回箭头
        compare_icon(value1, value2) {
            value1 = value1 ? value1 : 0;
            value2 = value2 ? value2 : 0;
            if (value1 > value2) {
                return 1
            } else if (value1 < value2) {
                return 3
            } else {
                return 2
            }
        },
        //切换筛选情况数据
        wipeData() {
            this.monthList = [];
            this.monthValue = null;
            this.weekList = [];
            this.weekValue = null;

        },
        switchGetData() {
            if (this.reportValue == 1) {
                this.integrationApi();
                //后侧
            } else if (this.reportValue == 2) {
                this.integrationApi();
                //月测   优化
            } else if (this.reportValue == 4) {
                this.getMonthTestList();
                //周测
            } else if (this.reportValue == 5) {
                this.getweekTestList();
            }
        },
        //决定reportType
        reportTypeChange() {
            // console.log('this.table_type', this.table_type);
            this.correctReportType = this.reportValue;
            console.log('调整后的type', this.correctReportType);

            // if (this.table_type == 1) {
            //     console.log(1111111111111);

            //     if (this.reportValue == 1) {
            //         //返回4-前测
            //         this.correctReportType = 4;
            //         this.reportCode = 'LZ3-C-00';
            //     } else if (this.reportValue == 2) {
            //         //返回5-后侧
            //         this.correctReportType = 5;
            //         this.reportCode = 'LZ3-C-01';
            //     } else if (this.reportValue == 3) {
            //         //返回4-月测
            //         this.correctReportType = 4;
            //     } else {
            //         //返回5-周测
            //         this.correctReportType = 5;
            //     }
            // } else {
            //     console.log(222222222222);
            //     if (this.reportValue == 1) {
            //         //返回1-前测
            //         this.correctReportType = 1;
            //     } else if (this.reportValue == 2) {
            //         //返回2-后侧
            //         this.correctReportType = 2;
            //     } else if (this.reportValue == 3) {
            //         //返回4-月测
            //         this.correctReportType = 4;
            //     } else {
            //         //返回5-周测
            //         this.correctReportType = 5;
            //     }
            //     console.log('this.correctReportType', this.correctReportType);

            // }
        },
        modify(value) {
            if (value == 1 || value == 4) {
                return 4;
            } else if ((value == 2 || value == 5)) {
                return 5
            }
        }



    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.getTermList();
        this.schoolName = this.$route.query.schoolName;
        // if (this.$route.query.fromType == 'multiRole') {
        //     if (this.$route.query.testType) {
        //         let testTypedata = this.$route.query.testType == 4 ? '3' : this.$route.query.testType;
        //         this.reportValue = Number(testTypedata);
        //         this.termValue = Number(this.$route.query.date);
        //         this.reportChange(this.reportValue)
        //     }

        // }

    },
}
</script>
<style lang='less' scoped>
@import url("./less/frontBackaAppraisal.less");
</style>