<template>
  <div class="participation">
    <div class="Sittingposture" :id="'Child_t' + Gradelist[Grade]">
      <div class="cephalosome flex-align-between">
        <div class="Secondary_heading_Grade">
          <!-- <div class="vertical_grain_icon"></div> -->
          <span class="title">{{ Grade }}年级分析</span>
        </div>
      </div>
      <div class="cephalosome cephalosome1 flex-align-between">
        <div class="Secondary_heading">
          <div class="vertical_grain_icon"></div>
          <span class="title">{{ Grade }}各等级占比</span>
        </div>
      </div>
      <div class="substance flex-between">
        <div class="excellent_rates">
          <h1>{{ schoolName }} {{ Grade }}各等级占比</h1>
          <!-- :high="Height4"
            :row_high="row_height4" -->
          <Short_form :construction="excellent_rates" :tabledata="excellent_rates_Data" :high="Height4"
            :row_high="row_height4" slashType="close">
          </Short_form>
        </div>
        <div class="excellent_rates_graphics">
          <h1>{{ schoolName }} {{ Grade }}各等级占比（%）</h1>
          <div class="excellent_ratesBox">
            <excellent_rates_graphicsMs :graphicsData="excellent_contrast_graphics" :id_type="Gradelist[Grade] + 'zb'">
            </excellent_rates_graphicsMs>
          </div>
        </div>
      </div>
      <div class="excellent_contrast">
        <h1>{{ Grade }}各等级与上次周测对比情况</h1>
        <div class="hold_mistake_table table_scrollbar">
          <el-table :data="excellent_contrast_Data" style="width: 100%" :stripe="true" empty-text="暂无数据">
            <el-table-column prop="date" label="周测" align="center">
            </el-table-column>
            <el-table-column prop="level4" :label="table_type == 1 ? '优秀+' : '优秀'" align="center">
              <template slot-scope="scope">
                <div class="imgUrlbox">
                  <img :src="imgUrl[`imgUrl${scope.row.level4_compare}`]" alt="" />
                </div>
                <span style="margin-left: 10px">{{ scope.row.level4=='null%'?'/':scope.row.level4 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="level3" :label="table_type == 1 ? '优秀' : '良好'" align="center">
              <template slot-scope="scope">
                <div class="imgUrlbox">
                  <img :src="imgUrl[`imgUrl${scope.row.level3_compare}`]" alt="" />
                </div>
                <span style="margin-left: 10px">{{ scope.row.level3=='null%'?'/':scope.row.level3 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="level2" :label="table_type == 1 ? '良好' : '合格'" align="center">
              <template slot-scope="scope">
                <div class="imgUrlbox">
                  <img :src="imgUrl[`imgUrl${scope.row.level2_compare}`]" alt="" />
                </div>
                <span style="margin-left: 10px">{{ scope.row.level2=='null%'?'/':scope.row.level2 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="level1" label="待提升" align="center">
              <template slot-scope="scope">
                <div class="imgUrlbox">
                  <img :src="imgUrl[`imgUrl${scope.row.level1_compare}`]" alt="" />
                </div>
                <span style="margin-left: 10px">{{ scope.row.level1=='null%'?'/':scope.row.level1 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="level5" :label="table_type == 1 ? '优秀率(优秀+&优秀)' : '优良率(优秀&良好)'" align="center">
              <template slot-scope="scope">
                <div class="imgUrlbox">
                  <img :src="imgUrl[`imgUrl${scope.row.level5_compare}`]" alt="" />
                </div>
                <span style="margin-left: 10px">{{ scope.row.level5 =='null%'?'/':scope.row.level5}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="excellent_rates_conclusion conclusion" v-if="comment6">
        <span class="prefix_title">结论4</span>
        {{ Grade }}
        <span v-if="comment6.k1 != '' && comment6.k2 != 0"> <span class="Comment_highlight">{{ comment6.k1 }}人数为{{
          comment6.k2 }}</span>，
          基本达标。</span>
        <!-- v-if="(comment6.thisTerm[0].rate + comment6.thisTerm[1].rate).toFixed(2) != 0" -->
        <!-- (comment6.thisTerm[0].rate + comment6.thisTerm[1].rate).toFixed(2) -->
        <span>整体{{ table_type == 1 ? '优秀率' : '优良率' }}为<span class="Comment_highlight">{{ comment6.thisTerm[4].rate
        }}%</span>，
          对比上次周测{{ excellent_contrast_Data[0].level5_compare == 1 ? '有所提升' : excellent_contrast_Data[0].level5_compare
            == 2 ? '无变化' : '有所下降' }}
        </span>。
      </div>
    </div>
    <div class="Sittingposture" :id="'Child_b' + Gradelist[Grade]">
      <div class="cephalosome cephalosome1 flex-align-between">
        <div class="Secondary_heading">
          <div class="vertical_grain_icon"></div>
          <span class="title">{{ Grade }}各班{{ table_type == 1 ? '优秀率' : '优良率' }}对比</span>
        </div>
      </div>
      <div class="substance flex-between">
        <div class="excellent_rates_total">
          <!-- :high="Height6":row_high="row_height6" -->
          <Short_form :construction="excellent_rates_class" :tabledata="excellent_class_Data" slashType="close">
          </Short_form>
        </div>
        <div class="excellent_rates_graphics">
          <!-- <h1>优秀率（优秀+&优秀）</h1> -->
          <div class="excellent_top">
            <div class="top">
              <div class="headTitle flex-align-center">
                <img src="../../../assets/newReport/trophy.png" alt="">
                {{ Grade }}年级TOP榜
              </div>
              <div class="toplist flex-align-around" v-for="(item, index) in gradeTopStudentlist.slice(0, 10)"
                :key="index">
                <div class="Number flex-layout">
                  <div class="imgbox" v-if="topimgUrl[index]">
                    <img :src="topimgUrl[index]">
                  </div>
                  <span class="flex-align-center" v-else> {{ index + 1 }}</span>
                </div>
                <div class="name">{{ item.name }}</div>
                <div class="achievement">{{ item.average }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Sittingposture" :id="'Child_z' + Gradelist[Grade]">
      <div class="excellent_contrast">
        <h1>{{ Grade }}各班{{ weekName }}周测评{{ table_type == 1 ? '优秀' : '优良' }}对比</h1>
        <div class="excellent_graph" :id="'excellent_graph' + Grade"></div>
      </div>
      <div class="excellent_rates_conclusion conclusion" v-if="excellentClassType">
        <span class="prefix_title">结论5</span>
        {{ table_type == 1 ? '优秀率（含优秀+&优秀）' : '优良率（含优秀&良好）' }}维度对比可见，<span
          v-showtype="{ value: excellentClass, Grade, table_type }"></span>。只要注意方法练，孩子们可塑性还是非常高的。
      </div>

    </div>
    <div class="Sittingposture" :id="'Child_i' + Gradelist[Grade]" v-if="workList.length > 0">
      <div class="outstanding_example" v-if="workList.length > 0">
        <div class="outstanding_tit flex-align-center">
          <img src="../../../assets/newReport/specimen.png" alt="" v-if="table_type == 1" />
          <img src="../../../assets/newReport/specimen2.png" alt="" v-else />
        </div>
        <div class="works flex-align-center">
          <el-image style="width: 100px; height: 100px" :src="workurl"></el-image>
        </div>
        <div class="changeset flex-align-center">
          <div class="changeset_btn flex-align-center" @click="stochastic">换一组查看</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Short_form from "./Short_form.vue";
import excellent_rates_graphicsMs from "./excellent_rates_graphicsMs.vue";
import Short_form_total from "./Short_form_total.vue";
export default {
  //import引入组件才能使用
  components: {
    Short_form,
    excellent_rates_graphicsMs,
    Short_form_total
  },
  props: ["Grade", "schoolName", "termValue", 'weekValue', 'weekName', 'table_type'],
  data() {
    return {
      //单项成绩各等级占比
      Gradelist: {
        一年级: 1,
        二年级: 2,
        三年级: 3,
        四年级: 4,
        五年级: 5,
        六年级: 6,
      },
      termValues: this.termValue,
      //等级占比
      excellent_rates: [
        {
          fieldName: "date",
          title: "等级",
        },
        {
          fieldName: "level4",
          title: this.table_type == 1 ? "优秀+" : '优秀',
        },
        {
          fieldName: "level3",
          title: this.table_type == 1 ? "优秀" : '良好',
        },
        {
          fieldName: "level2",
          title: this.table_type == 1 ? "良好" : '合格',
        },
        {
          fieldName: "level1",
          title: "待提升",
        },
      ],
      //等级占比数据
      excellent_rates_Data: [
        {
          date: "人数",
          level1: 0,
          level2: 0,
          level3: 0,
          level4: 0,
        },
        {
          date: "占比",
          level1: 0,
          level2: 0,
          level3: 0,
          level4: 0,
        },
      ],
      Height4: "255px",
      row_height4: "84px",
      //等级占比图例
      excellent_contrast_graphics: {
        idName: "excellent_contrast_graphics",
        title: "各等级占比（%）",
        yData: this.table_type == 1 ? ["优秀+", "优秀", "良好", "待提升"] : ["优秀", "良好", "合格", "待提升"],
        semester1: [0, 0, 0, 0],
        colordata: ["rgba(28, 141, 232, 1)", "rgba(97, 229, 238,1)"],
      },
      topimgUrl: [
        require('@/assets/newReport/first.png'),
        require('@/assets/newReport/second.png'),
        require('@/assets/newReport/third.png'),
      ],
      //年级各等级与上次月测对比情况
      excellent_contrast_Data: [{
        date: '本次月测',
        level1: 0,
        level2: 0,
        level3: 0,
        level4: 0,
        level5: 0
      },
      {
        date: '上次月测',
        level1: 0,
        level2: 0,
        level3: 0,
        level4: 0,
        level5: 0
      },
      ],
      //箭头类型
      imgUrl: {
        imgUrl1: require('@/assets/newReport/greent.png'),
        imgUrl2: require('@/assets/newReport/or_r.png'),
        imgUrl3: require('@/assets/newReport/redx.png'),
      },
      comment6: null,
      //各班优秀率对比
      excellent_rates_class: [{
        fieldName: 'className',
        title: '班级'
      },
      {
        fieldName: 'level4',
        title: this.table_type == 1 ? '优秀+' : '优秀'
      },
      {
        fieldName: 'level3',
        title: this.table_type == 1 ? '优秀' : '良好'
      },
      {
        fieldName: 'level2',
        title: this.table_type == 1 ? '良好' : '合格'
      },
      {
        fieldName: 'level1',
        title: '待提升'
      },
      {
        fieldName: 'excellentRate',
        title: this.table_type == 1 ? '优秀率' : '优良率'
      },
      ],
      //各班优秀率对比数据
      excellent_class_Data: [],
      Height6: '302',
      row_height6: '21',
      //图例
      excellent_class_graphics: {
        // idName: 'excellent_class_graphics',
        // title: '优秀率（优秀+&优秀）',
        yData: [],
        semester1: [0, 0, 0, 0],
        semester2: [0, 0, 0, 0],
        colordata: [
          'rgba(233, 170, 19, 1)',
          'rgba(251, 238, 208,0.8)',
        ],
      },
      excellentClass: [],
      excellentClassType: false,
      workList: [],
      limit: 2,
      pageSize: 1,
      gradeTopStudentlist: [],
      workurl: '',
    };
  },
  directives: {
    showtype: {
      update: function (el, binding) {
        console.log('el:',el)
        console.log('binding:',binding)

        let data = binding.value;
        let Gname = data.table_type == 1 ? '优秀率' : '优良率';
        if (data.value.length == 0) {
          el.innerHTML = '<span><span class="Comment_highlight">' + data.Grade + '</span>' + Gname + '为<span class="Comment_highlight">0.00%</span></span>';
        } else {

          let maxValue = Math.max(...data.value.map(obj => obj.excellentRate));
          let minValue = Math.min(...data.value.map(obj => obj.excellentRate));
          console.log('maxValue:',maxValue)
          console.log('minValue:',minValue)

          if (maxValue == minValue) {
            el.innerHTML = '<span><span class="Comment_highlight">' + data.Grade + '</span>' + Gname + '为<span class="Comment_highlight">' + maxValue.toFixed(2) + '%</span></span>';
          } else {
            let maxObjects = data.value.filter(obj => obj.excellentRate == maxValue);
            let minObjects = data.value.filter(obj => obj.excellentRate == minValue);
            console.log('maxObjects:',maxObjects)
          console.log('minObjects:',minObjects)
            let maxindex = maxObjects.length;
            let minindex = minObjects.length;
            let maxtext = '';
            let mintext = '';
            if (maxindex == 2) {
              maxtext = maxObjects[0].className + '和' + maxObjects[1].className
            } else if ((maxindex >= 3)) {
              // maxtext = maxObjects[0].className + '、' + maxObjects[1].className + '、' + maxObjects[2].className + '等'
              maxtext = maxObjects[0].className + '、' + maxObjects[1].className + '等'
            } else {
              maxtext = maxObjects[0].className
            }
            if (minindex == 2) {
              mintext = minObjects[0].className + '和' + minObjects[1].className
            } else if ((minindex >= 3)) {
              // mintext = minObjects[0].className + '、' + minObjects[1].className + '、' + minObjects[2].className + '等'
              mintext = minObjects[0].className + '、' + minObjects[1].className + '等'
            } else {
              mintext = minObjects[0].className
            }
            let zui = '<span><span class="Comment_highlight">' + maxtext + '</span>' + Gname + '最高</span>，<span><span class="Comment_highlight">' + mintext + '</span>' + Gname + '最低</span>';
            return el.innerHTML = zui;

          }
        }
      },

    }
  },
  // 计算属性
  computed: {},
  // 监听data中的数据变化
  watch: {

  },
  // 方法集合
  methods: {
    //测评优秀对比
    excellent_graph() {
      this.$echarts.init(document.getElementById("excellent_graph" + this.Grade)).dispose();
      let that = this;
      let myChart = this.$echarts.init(
        document.getElementById("excellent_graph" + this.Grade)
      );
      let semesterData1 = this.excellent_class_graphics.semester1.map(num => Math.floor(num));
      let maxData = Math.max(...semesterData1);
      let minData = Math.min(...semesterData1);
      //最大值等于100 不加5 && 最小值小于10不减5
      if (maxData < 100) {
        maxData + 5;
      }
      if (minData > 10) {
        maxData - 5;
      }
      myChart.setOption({
        // tooltip: {
        //   trigger: 'axis',
        //   formatter: function (params) {
        //   },
        // },
        legend: {
          data: this.table_type == 1 ? ['优秀率', '全年级优秀率'] : ['优良率', '全年级优良率'],
          textStyle: {
            fontSize: this.graphicRatio(12),
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          axisLabel: {
            fontSize: this.graphicRatio(14),
            rotate: 45,
          },
          data: this.excellent_class_graphics.yData
        },
        yAxis: {
          type: 'value',
          min: minData,
          max: maxData,
          axisLine: {
            show: true, // 显示x轴线
          },
          axisLabel: {
            fontSize: this.graphicRatio(14),
            formatter: function (value) {
              return `${value}%`; // 添加百分比符号
            },
          },
        },
        series: [
          {
            name: this.table_type == 1 ? '优秀率' : '优良率',
            type: 'line',
            symbol: 'none',
            // label: {
            //   show: true,
            //   position: 'top',
            //   formatter: function (params) {
            //     return params.value + '%';
            //   }
            // },
            itemStyle: {
              color: '#3875ff'
            },
            data: this.excellent_class_graphics.semester1,
          },
          {
            // name: '优秀率',
            type: 'bar',
            barWidth: this.graphicRatio(1),
            symbol: 'none',

            label: {
              show: true,
              position: 'top',
              color: '#3875ff',
              fontSize: this.graphicRatio(14),
              formatter: function (params) {
                return params.value + '%';
              }
            },
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                0, // x0
                0, // y0
                0, // x1
                1, // y1
                [
                  { offset: 0, color: '#759fff' }, // 上方颜色
                  { offset: 0.5, color: '#759fff' }, // 上方颜色
                  { offset: 1, color: '#dbe6ff' }, // 下方颜色
                ]
              ),
            },
            data: this.excellent_class_graphics.semester1,
          },
          {
            name: this.table_type == 1 ? '全年级优秀率' : '全年级优良率',
            type: 'line',
            symbol: 'none',
            smooth: false,
            itemStyle: {
              color: '#fdc418'
            },
            data: this.excellent_class_graphics.semester2,
          },
          {
            // name: '全年级优秀率',
            type: 'bar',
            barWidth: '1',
            symbol: 'none',
            label: {
              show: true,
              position: 'top',
              color: '#fdc418',
              fontSize: this.graphicRatio(14),
              formatter: function (params) {
                const dataLength = that.excellent_class_graphics.semester2.length - 1;
                return params.dataIndex === dataLength ? params.value + '%' : '';
              }
            },
            itemStyle: {
              color: 'transparent',
            },
            data: this.excellent_class_graphics.semester2,
          },
        ]
      });
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    stochastic() {
      const randomIndex = Math.floor(Math.random() * this.workList.length);
      this.workurl = this.workList[randomIndex];
    },
    // 本年级各等级学生人数占比
    async getgradeGradeRatioV2_month() {
      let data = {
        schoolId: this.$route.query.schoolId,
        termKey: this.termValue,
        testId: this.weekValue,
        examType: 5,
        grade: this.Gradelist[this.Grade]
      };

      let res = await this.$Api.Report.getgradeGradeRatioV2_month(data);
      let num = {
        date: "人数",
        level1: res.data.thisTerm[3].userNum,
        level2: res.data.thisTerm[2].userNum,
        level3: res.data.thisTerm[1].userNum,
        level4: res.data.thisTerm[0].userNum,
      };
      let rate = {
        date: "占比",
        level1: res.data.thisTerm[3].rate + "%",
        level2: res.data.thisTerm[2].rate + "%",
        level3: res.data.thisTerm[1].rate + "%",
        level4: res.data.thisTerm[0].rate + "%",
      };
      // //各等级占比
      this.excellent_rates_Data = [num, rate];
      let lastTerm;
      let thisTerm = {
        date: "本次周测",
        level1: res.data.thisTerm[3].rate + "%",
        level2: res.data.thisTerm[2].rate + "%",
        level3: res.data.thisTerm[1].rate + "%",
        level4: res.data.thisTerm[0].rate + "%",
        // level5: (res.data.thisTerm[1].rate + res.data.thisTerm[0].rate).toFixed(2) + "%",
        level5: res.data.thisTerm[4].rate + "%",
        // 本学期与上学期对比 1高于 2相等 3低于
        level1_compare: res.data.thisTerm[3].rate > res.data.lastTerm[3].rate ? 1 : res.data.thisTerm[3].rate == res.data.lastTerm[3].rate ? 2 : 3,
        level2_compare: res.data.thisTerm[2].rate > res.data.lastTerm[2].rate ? 1 : res.data.thisTerm[2].rate == res.data.lastTerm[2].rate ? 2 : 3,
        level3_compare: res.data.thisTerm[1].rate > res.data.lastTerm[1].rate ? 1 : res.data.thisTerm[1].rate == res.data.lastTerm[1].rate ? 2 : 3,
        level4_compare: res.data.thisTerm[0].rate > res.data.lastTerm[0].rate ? 1 : res.data.thisTerm[0].rate == res.data.lastTerm[0].rate ? 2 : 3,
        // level5_compare: res.data.thisTerm[1].rate + res.data.thisTerm[0].rate > res.data.lastTerm[1].rate + res.data.lastTerm[0].rate ? 1 : res.data.thisTerm[1].rate + res.data.thisTerm[0].rate == res.data.lastTerm[1].rate + res.data.lastTerm[0].rate ? 2 : 3,
        level5_compare: res.data.thisTerm[4].rate > res.data.lastTerm[4].rate ? 1 : res.data.thisTerm[4].rate == res.data.lastTerm[4].rate ? 2 : 3,
      };
      if (res.data.lastTerm[0].rate == 0 && res.data.lastTerm[1].rate == 0 && res.data.lastTerm[2].rate == 0 && res.data.lastTerm[3].rate == 0) {
        lastTerm = {
          date: "上次周测",
          // level1: "/",
          // level2: "/",
          // level3: "/",
          // level4: "/",
          // level5: "/",
          level1: "0%",
          level2: "0%",
          level3: "0%",
          level4: "0%",
          level5: "0%",
        };
      } else {
        lastTerm = {
          date: "上次周测",
          level1: res.data.lastTerm[3].rate == 0 ? "0%" : res.data.lastTerm[3].rate + "%",
          level2: res.data.lastTerm[2].rate == 0 ? "0%" : res.data.lastTerm[2].rate + "%",
          level3: res.data.lastTerm[1].rate == 0 ? "0%" : res.data.lastTerm[1].rate + "%",
          level4: res.data.lastTerm[0].rate == 0 ? "0%" : res.data.lastTerm[0].rate + "%",
          // level5: res.data.lastTerm[1].rate + res.data.lastTerm[0].rate == 0 ? "0%" : (res.data.lastTerm[1].rate + res.data.lastTerm[0].rate).toFixed(2) + "%",
          level5: res.data.lastTerm[4].rate == 0 ? "0%" : res.data.lastTerm[4].rate + "%",
        };
      }
      //各等级与上学期对比情况
      this.excellent_contrast_Data = [thisTerm, lastTerm];
      let semester1 = [];
      res.data.thisTerm.map((item) => {
        semester1.push(item.rate);
      });
      // // 各等级占比（%）
      this.$set(this.excellent_contrast_graphics, "semester1", semester1);
      this.comment6 = res.data;
    },
    // 本年级各班优秀率对比
    async getgradeClassExcellentRateDiffV2_month() {
      let data = {
        schoolId: this.$route.query.schoolId,
        termKey: this.termValue,
        testId: this.weekValue,
        examType: 5,
        grade: this.Gradelist[this.Grade]
      };
      let res = await this.$Api.Report.getgradeClassExcellentRateDiffV2_month(data);
      let data1 = [];
      let data2 = [];
      let yData = [];
      const originaldata = res.data;
      let objArray = JSON.parse(JSON.stringify(originaldata));
      objArray.map(item => {
        item.excellentRate = item.excellentRate + '%';
      })
      this.excellent_class_Data = objArray;
      let fullSchoolRankStudents = originaldata.filter(student => student.className != '全年级');
      let universityWide = originaldata.filter(student => student.className === '全年级');
      this.excellentClassType = true;
      if (universityWide[0].excellentRate != 0 && fullSchoolRankStudents != []) {
        this.excellentClass = fullSchoolRankStudents;
      }
      for (let index = 0; index < fullSchoolRankStudents.length; index++) {

        data1.push(fullSchoolRankStudents[index].excellentRate);
        data2.push(universityWide[0].excellentRate);
        yData.push(fullSchoolRankStudents[index].className);
      }
      this.$set(this.excellent_class_graphics, 'semester1', data1);
      this.$set(this.excellent_class_graphics, 'semester2', data2);
      this.$set(this.excellent_class_graphics, 'yData', yData);
      this.excellent_graph();
    },
    //top榜
    async getgradeTopStudentV2_month() {
      let data = {
        schoolId: this.$route.query.schoolId,
        termKey: this.termValue,
        testId: this.weekValue,
        examType: 5,
        grade: this.Gradelist[this.Grade]
      };
      let res = await this.$Api.Report.getgradeTopStudentV2_month(data);
      this.gradeTopStudentlist = res.data;
    },
    // 优秀作品
    async getWorksSch_month() {
      this.workList = [];
      let data = {
        schoolId: this.$route.query.schoolId,
        termKey: this.termValue,
        testId: this.weekValue,
        examType: 5,
        grade: this.Gradelist[this.Grade]
      };
      let res = await this.$Api.Report.getWorksSch_month(data);
      res.data.records.map(item => {
        this.workList.push(item.filePath)
      });
      this.workurl = this.workList[0];
    }
  },
  // 生命周期，创建完成时（可以访问当前this实例）
  created() { },
  // 生命周期：挂载完成时（可以访问DOM元素）
  mounted() {
    this.getgradeGradeRatioV2_month();
    this.getgradeClassExcellentRateDiffV2_month();
    this.getgradeTopStudentV2_month();
    this.getWorksSch_month();
  },
};
</script>
<style lang='less' scoped>
@import url("../less/frontBackaAppraisal.less");

.cephalosome1 {
  margin-top: 15px !important;
}
</style>
