<template>
    <div class="old_School_score">
        <div class="substance">
            <div class="performance_proportion table_scrollbar">
                <el-table :data="tablaData_school" style="width: 100%" :stripe="true" empty-text="暂无数据">
                    <el-table-column prop="indexRank" label="维度" align="center"></el-table-column>
                    <el-table-column prop="centerPosAvg" label="居中占位" align="center">
                        <template slot-scope="scope">
                            <div class="imgUrlbox" v-if="scope.row.centerPosAvg_compare && scope.row.centerPosAvg != null">
                                <img :src="imgUrl[`imgUrl${scope.row.centerPosAvg_compare}`]" alt="">
                            </div>
                            <span style="margin-left: 10px">{{ scope.row.centerPosAvg == null ? '/' :
                                Number(scope.row.centerPosAvg).toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="gridSizeAvg" label="占格大小" align="center">
                        <template slot-scope="scope">
                            <div class="imgUrlbox" v-if="scope.row.gridSizeAvg_compare && scope.row.gridSizeAvg != null">
                                <img :src="imgUrl[`imgUrl${scope.row.gridSizeAvg_compare}`]" alt="">
                            </div>
                            <span style="margin-left: 10px">{{ scope.row.gridSizeAvg == null ? '/' :
                                Number(scope.row.gridSizeAvg).toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="glyphGravAvg" label="字形重心" align="center">
                        <template slot-scope="scope">
                            <div class="imgUrlbox" v-if="scope.row.glyphGravAvg_compare && scope.row.glyphGravAvg != null">
                                <img :src="imgUrl[`imgUrl${scope.row.glyphGravAvg_compare}`]" alt="">
                            </div>
                            <span style="margin-left: 10px">{{ scope.row.glyphGravAvg == null ? '/' :
                                Number(scope.row.glyphGravAvg).toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="comboRelAvg" label="组合关系" align="center">
                        <template slot-scope="scope">
                            <div class="imgUrlbox" v-if="scope.row.comboRelAvg_compare && scope.row.comboRelAvg != null">
                                <img :src="imgUrl[`imgUrl${scope.row.comboRelAvg_compare}`]" alt="">
                            </div>
                            <span style="margin-left: 10px">{{ scope.row.comboRelAvg == null ? '/' :
                                Number(scope.row.comboRelAvg).toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="rewindRelAvg" label="收放关系" align="center">
                        <template slot-scope="scope">
                            <div class="imgUrlbox" v-if="scope.row.rewindRelAvg_compare && scope.row.rewindRelAvg != null">
                                <img :src="imgUrl[`imgUrl${scope.row.rewindRelAvg_compare}`]" alt="">
                            </div>
                            <span style="margin-left: 10px">{{ scope.row.rewindRelAvg == null ? '/' :
                                Number(scope.row.rewindRelAvg).toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="multiDistAvg" label="多画等距" align="center">
                        <template slot-scope="scope">
                            <div class="imgUrlbox" v-if="scope.row.multiDistAvg_compare && scope.row.multiDistAvg != null">
                                <img :src="imgUrl[`imgUrl${scope.row.multiDistAvg_compare}`]" alt="">
                            </div>
                            <span style="margin-left: 10px">{{ scope.row.multiDistAvg == null ? '/' :
                                Number(scope.row.multiDistAvg).toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="strokeRelAvg" label="笔画关系" align="center">
                        <template slot-scope="scope">
                            <div class="imgUrlbox" v-if="scope.row.strokeRelAvg_compare && scope.row.strokeRelAvg != null">
                                <img :src="imgUrl[`imgUrl${scope.row.strokeRelAvg_compare}`]" alt="">
                            </div>
                            <span style="margin-left: 10px">{{ scope.row.strokeRelAvg == null ? '/' :
                                Number(scope.row.strokeRelAvg).toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="integrityAvg" label="作品完整度" align="center">
                        <template slot-scope="scope">
                            <div class="imgUrlbox" v-if="scope.row.integrityAvg_compare && scope.row.integrityAvg != null">
                                <img :src="imgUrl[`imgUrl${scope.row.integrityAvg_compare}`]" alt="">
                            </div>
                            <span style="margin-left: 10px">{{ scope.row.integrityAvg == null ? '/' :
                                Number(scope.row.integrityAvg).toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="performance_graphics">
                <!-- <various_graphics :seriesData="various_graphics_data" :variousData="variousData"
                    :variousType="currentReportType">
                </various_graphics> -->
                <various_graphics :seriesData="seriesData" :variousData="variousData" :variousType="variousType">
                </various_graphics>
            </div>
        </div>
        <div class="semester flex-column-center">
            <h1>与上一学期测评对比</h1>
            <div class="semester_table table_scrollbar">
                <el-table :data="tableData_compare" style="width: 100%" :stripe="true" empty-text="暂无数据">
                    <el-table-column prop="indexRank" label="上学期对比" align="center" width="80"> </el-table-column>
                    <el-table-column label="居中占位" align="center">
                        <el-table-column prop="centerPosAvgBen" label="本学期" align="center" width="73">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"
                                    v-if="scope.row.centerPosAvg_compare && scope.row.centerPosAvgBen != null">
                                    <img :src="imgUrl[`imgUrl${scope.row.centerPosAvg_compare}`]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ !scope.row.centerPosAvgBen ? '/' :
                                    scope.row.centerPosAvgBen }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="centerPosAvgLast" label="上学期" align="center" width="73">
                            <template slot-scope="scope">
                                <span>{{ !scope.row.centerPosAvgLast ? '/' :
                                    scope.row.centerPosAvgLast }}</span>
                                <!-- <span>{{ scope.row.centerPosAvgLast }}</span> -->
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="占格大小" align="center">
                        <el-table-column prop="gridSizeAvgBen" label="本学期" align="center" width="73">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"
                                    v-if="scope.row.gridSizeAvg_compare && scope.row.gridSizeAvgBen != null">
                                    <img :src="imgUrl[`imgUrl${scope.row.gridSizeAvg_compare}`]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ !scope.row.gridSizeAvgBen ? '/' :
                                    scope.row.gridSizeAvgBen }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="gridSizeAvgLast" label="上学期" align="center" width="73">
                            <template slot-scope="scope">
                                <span>{{ !scope.row.gridSizeAvgLast ? '/' :
                                    scope.row.gridSizeAvgLast }}</span>
                                <!-- <span>{{ scope.row.gridSizeAvgLast }}</span> -->
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="字形重心" align="center">
                        <el-table-column prop="glyphGravAvgBen" label="本学期" align="center" width="73">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"
                                    v-if="scope.row.glyphGravAvg_compare && scope.row.glyphGravAvgBen != null">
                                    <img :src="imgUrl[`imgUrl${scope.row.glyphGravAvg_compare}`]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ !scope.row.glyphGravAvgBen? '/' :
                                    scope.row.glyphGravAvgBen }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="glyphGravAvgLast" label="上学期" align="center" width="73">
                            <template slot-scope="scope">
                                <span>{{ !scope.row.glyphGravAvgLast? '/' :
                                    scope.row.glyphGravAvgLast }}</span>
                                <!-- <span>{{ scope.row.glyphGravAvgLast }}</span> -->
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="组合关系" align="center">
                        <el-table-column prop="comboRelAvgBen" label="本学期" align="center" width="73">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"
                                    v-if="scope.row.comboRelAvg_compare && scope.row.comboRelAvgBen != null">
                                    <img :src="imgUrl[`imgUrl${scope.row.comboRelAvg_compare}`]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ !scope.row.comboRelAvgBen? '/' :
                                    scope.row.comboRelAvgBen }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="comboRelAvgLast" label="上学期" align="center" width="73">
                            <template slot-scope="scope">
                                <span>{{ !scope.row.comboRelAvgLast? '/' :
                                    scope.row.comboRelAvgLast }}</span>
                                <!-- <span>{{ scope.row.comboRelAvgLast }}</span> -->
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="收放关系" align="center">
                        <el-table-column prop="rewindRelAvgBen" label="本学期" align="center" width="73">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"
                                    v-if="scope.row.rewindRelAvg_compare && scope.row.rewindRelAvgBen != null">
                                    <img :src="imgUrl[`imgUrl${scope.row.rewindRelAvg_compare}`]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ !scope.row.rewindRelAvgBen? '/' :
                                    scope.row.rewindRelAvgBen }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="rewindRelAvgLast" label="上学期" align="center" width="73">
                            <template slot-scope="scope">
                                <span>{{ !scope.row.rewindRelAvgLast? '/' :
                                    scope.row.rewindRelAvgLast }}</span>
                                <!-- <span>{{ scope.row.rewindRelAvgLast }}</span> -->
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="多画等距" align="center">
                        <el-table-column prop="multiDistAvgBen" label="本学期" align="center" width="73">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"
                                    v-if="scope.row.multiDistAvg_compare && scope.row.multiDistAvgBen != null">
                                    <img :src="imgUrl[`imgUrl${scope.row.multiDistAvg_compare}`]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ !scope.row.multiDistAvgBen? '/' :
                                    scope.row.multiDistAvgBen }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="multiDistAvgLast" label="上学期" align="center" width="73">
                            <template slot-scope="scope">
                                <span>{{ !scope.row.multiDistAvgLast? '/' :
                                    scope.row.multiDistAvgLast }}</span>
                                <!-- <span>{{ scope.row.multiDistAvgLast }}</span> -->
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="笔画关系" align="center">
                        <el-table-column prop="strokeRelAvgBen" label="本学期" align="center" width="73">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"
                                    v-if="scope.row.strokeRelAvg_compare && scope.row.strokeRelAvgBen != null">
                                    <img :src="imgUrl[`imgUrl${scope.row.strokeRelAvg_compare}`]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ !scope.row.strokeRelAvgBen? '/' :
                                    scope.row.strokeRelAvgBen }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="strokeRelAvgLast" label="上学期" align="center" width="73">
                            <template slot-scope="scope">
                                <span>{{ !scope.row.strokeRelAvgLast? '/' :
                                    scope.row.strokeRelAvgLast }}</span>
                                <!-- <span>{{ scope.row.strokeRelAvgLast }}</span> -->
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="作品完整度" align="center">
                        <el-table-column prop="integrityAvgBen" label="本学期" align="center" width="73">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"
                                    v-if="scope.row.integrityAvg_compare && scope.row.integrityAvgBen != null">
                                    <img :src="imgUrl[`imgUrl${scope.row.integrityAvg_compare}`]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ !scope.row.integrityAvgBen? '/' :
                                    scope.row.integrityAvgBen }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="integrityAvgLast" label="上学期" align="center" width="73">
                            <template slot-scope="scope">
                                <span>{{ !scope.row.integrityAvgLast? '/' :
                                    scope.row.integrityAvgLast }}</span>
                                <!-- <span>{{ scope.row.integrityAvgLast }}</span> -->
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
            <div class="semester_conclusion conclusion" v-if="comment5">
                <span class="prefix_title">结论5</span><br />
                <span>(1) 居中占位维度：平均成绩处于<span class="Comment_highlight">{{ comment5['k-centerPosAvg'] }}</span>水平，
                    <span class="Comment_highlight">{{
                        computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                            'centerPosAvgBen') }}较好</span>。
                    <br />(2) 占格大小维度：平均成绩处于<span class="Comment_highlight">{{ comment5['k-gridSizeAvg'] }}</span>水平，
                    <span class="Comment_highlight">{{
                        computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                            'gridSizeAvgBen') }}较好</span>。
                    <br />(3) 字形重心维度：平均成绩处于<span class="Comment_highlight">{{ comment5['k-glyphGravAvg'] }}</span>水平，
                    <span class="Comment_highlight">{{
                        computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                            'glyphGravAvgBen') }}较好</span>。
                    <br />(4) 组合关系维度：平均成绩处于<span class="Comment_highlight">{{ comment5['k-comboRelAvg'] }}</span>水平，
                    <span class="Comment_highlight">{{
                        computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                            'comboRelAvgBen') }}较好</span>。
                    <br />(5) 收放关系维度：平均成绩处于<span class="Comment_highlight">{{ comment5['k-rewindRelAvg'] }}</span>水平，
                    <span class="Comment_highlight">{{
                        computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                            'rewindRelAvgBen') }}较好</span>。
                    <br />(6) 多画等距维度：平均成绩处于<span class="Comment_highlight">{{ comment5['k-multiDistAvg'] }}</span>水平，
                    <span class="Comment_highlight">{{
                        computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                            'multiDistAvgBen') }}较好</span>。
                    <br />(7) 笔画关系维度：平均成绩处于<span class="Comment_highlight">{{ comment5['k-strokeRelAvg'] }}</span>水平，
                    <span class="Comment_highlight">{{
                        computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                            'strokeRelAvgBen') }}较好</span>。
                    <br />(8) 作品完整度维度：平均成绩处于<span class="Comment_highlight">{{ comment5['k-integrityAvg'] }}</span>水平，
                    <span class="Comment_highlight">{{
                        computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                            'integrityAvgBen') }}较好</span>。
                </span>
                <p>相较上个学期，在
                    <span class="Comment_highlight">居中占位</span>维度，
                    {{ computedLastTerm(comment5.resultDiffList, 'centerPosAvg') }}；
                    <span class="Comment_highlight">占格大小</span>维度，
                    {{ computedLastTerm(comment5.resultDiffList, 'gridSizeAvg') }}；
                    <span class="Comment_highlight">字形重心</span>维度，
                    {{ computedLastTerm(comment5.resultDiffList, 'glyphGravAvg') }}；
                    <span class="Comment_highlight">组合关系</span>维度，
                    {{ computedLastTerm(comment5.resultDiffList, 'comboRelAvg') }}；
                    <span class="Comment_highlight">收放关系</span>维度，
                    {{ computedLastTerm(comment5.resultDiffList, 'rewindRelAvg') }}；
                    <span class="Comment_highlight">多画等距</span>维度，
                    {{ computedLastTerm(comment5.resultDiffList, 'multiDistAvg') }}；
                    <span class="Comment_highlight">笔画关系</span>维度，
                    {{ computedLastTerm(comment5.resultDiffList, 'strokeRelAvg') }}；
                    <span class="Comment_highlight">作品完整度</span>维度，
                    {{ computedLastTerm(comment5.resultDiffList, 'integrityAvg') }}。
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import various_graphics from '../appraisalCharts/various_graphics.vue'
export default {
    //import引入组件才能使用
    components: {
        various_graphics
    },
    props: ['tablaData_school', 'seriesData', 'variousData', 'variousType', 'tableData_compare', 'comment5'],
    data() {
        return {
            imgUrl: {
                imgUrl1: require('@/assets/newReport/greent.png'),
                imgUrl2: require('@/assets/newReport/or_r.png'),
                imgUrl3: require('@/assets/newReport/redx.png'),
            },
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    // 方法集合
    methods: {
        // 结论5 计算各维度成绩前两名
        computedComment5(list, name) {
            list.sort((a, b) => b[name] - a[name]);
            try {
                return `${list[0].indexRank}`

            } catch (err) {
                console.log('计算结论5出报错:', err)
            }

        },
        // 结论5 计算各维度与上学期对比
        computedLastTerm(list, name) {
            let className = [];
            let comment = '';
            list.map(item => {
                if (item[`${name}ben`] > item[`${name}last`]) {
                    className.push(item.indexRank)
                }
            })
            if (className.length == list.length) {
                comment = '各年级都有所提升'
            } else {
                comment = `${className.join('、')}相较上个学期有所提升`
            }
            return comment
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        //提价
        // this.filterArrayB();
        console.log('+++++++++++++++++++');
        
    },
}
</script>
<style scoped lang="less" scoped>
.substance {
    margin-left: 54px;
    margin-right: 50px;
    margin-top: 30px;
    // border: 1px solid #000;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .performance_proportion {
        // width: 818px;
        width: 998px;

        /deep/.el-table {
            .cell {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .el-table__cell {
                padding: 6px 0 !important;
            }
        }
    }

    .table_scrollbar {
        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: #e4e4e4;
            border-radius: 3px;
        }

        //滚动条的滑块
        ::-webkit-scrollbar-thumb {
            background-color: #c8c9cc;
            border-radius: 3px;
        }

        /deep/.el-table {
            border-radius: 10px;
            border: 1px solid #E8E8E8;

            th {
                text-align: center;
                background: #F2FBF9;
                color: #01B58C;
            }
        }
    }

    .performance_graphics {
        width: 380px;
        height: 380px;
        // width: 560px;
        // height: 400px;
        // border: 1px solid #000;
    }

}

.semester {
    margin-top: 41px;
    margin-left: 54px;
    margin-right: 50px;
    // border: 1px solid #000;

    h1 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 19px;
    }

    .semester_table {
        width: 100%;
        margin-bottom: 35px;

        /deep/.el-table {
            .cell {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .semester_conclusion {
        width: 100%;
    }

    .table_scrollbar {
        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: #e4e4e4;
            border-radius: 3px;
        }

        //滚动条的滑块
        ::-webkit-scrollbar-thumb {
            background-color: #c8c9cc;
            border-radius: 3px;
        }

        /deep/.el-table {
            border-radius: 10px;
            border: 1px solid #E8E8E8;

            th {
                text-align: center;
                background: #F2FBF9;
                color: #01B58C;
            }
        }
    }

    .semester_conclusion {
        width: 100%;
        // border: 1px solid #000;
    }
}

.conclusion {
    border: 1px solid transparent;
    padding: 25px;
    // width: 100%;
    min-height: 70px;
    background: #fafafa;
    border-radius: 10px;
    font-size: 15px;
    line-height: 27px;
    margin-top: 20px;

    /deep/.Comment_highlight {
        color: #E96F02;
        font-weight: bold;
    }
}

.prefix_title {
    display: inline-block;
    width: 85px;
    height: 27px;
    background: #01B58C;
    border-radius: 14px;
    text-align: center;
    line-height: 27px;
    color: #fff;
    margin-right: 10px;
    font-weight: bold;
    margin-bottom: 10px;

}
</style>