<template>
    <div class="monthTest">
        <!-- 学校名称-学期 -->
        <div class="type_title flex-column-center" id="type_title">
            <h1>{{ schoolName }}周测报告</h1>
            <h2>{{ weekName }}</h2>
        </div>
        <div class="overall_situation">
            <h1 class="First_level_heading">1.整体情况</h1>
            <!-- 参与情况 -->
            <div class="participation" id="pdf1">
                <div class="cephalosome flex-align-between">
                    <div class="Secondary_heading">
                        <div class="vertical_grain_icon"></div> <span class="title">参与情况</span>
                    </div>
                </div>
                <div class="substance flex-between">
                    <div class="participation_form">
                        <div class="particulars">
                            <!-- :high="Height1"
                                :row_high="row_height1" -->
                            <Short_form :construction="participation_construction" :tabledata="joinData"
                                :row_high="row_height1" slashType="close">
                            </Short_form>
                        </div>
                        <div class="conclusion" v-if="joinData.length > 0">
                            <span class="prefix_title">结论1</span>
                            本次测评活动共有<span class="Comment_highlight"> {{ stuCount }} </span>个学生参与，
                            共回收到有效测评试卷<span class="Comment_highlight"> {{ pageCount }} </span>页。
                            <span v-if="joinData.length > 1 && !comment_one">
                                其中<span class="Comment_highlight">{{ compare(joinData, 'participationRate', 'maxRate') }}
                                </span>参与率最高，<span class="Comment_highlight"> {{ compare(joinData,
                                    'participationRate', 'minRate') }} </span>参与率最低。
                            </span>
                        </div>
                    </div>
                    <div class="participation_graphics">
                        <participationRate :joinData="joinRate_graphics" :termKey="termValue" :type="'week'">
                        </participationRate>
                    </div>
                </div>
            </div>
            <!-- 全校各等级学员占比 -->
            <div class="participation" id="pdf5">
                <div class="cephalosome flex-align-between">
                    <!-- //标题 -->
                    <div class="Secondary_heading">
                        <div class="vertical_grain_icon"></div> <span class="title">全校各等级学员占比</span>
                    </div>
                </div>
                <div class="substance flex-between">
                    <div class="grade_proportion">
                        <Short_form :construction="grade_construction" :tabledata="grade_data" :row_high="row_height2"
                            slashType="close">
                        </Short_form>
                    </div>
                    <div class="participation_graphics2">
                        <div class="stuProportion" id="stuProportion"></div>
                    </div>
                </div>
                <div class="hold_mistake flex-column-center">
                    <h1>与上次周测等级对比</h1>
                    <div class="hold_mistake_table table_scrollbar">
                        <el-table :data="holdData" style="width: 100%" :stripe="true" empty-text="暂无数据">
                            <el-table-column prop="term" label="周测" align="center">
                            </el-table-column>
                            <el-table-column prop="level1" :label="table_type == 1 ? '优秀+' : '优秀'" align="center">
                                <template slot-scope="scope">
                                    <div class="imgUrlbox"> <img :src="imgUrl[`imgUrl${scope.row.level1_compare}`]" alt="">
                                    </div>
                                    <span style="margin-left: 10px">{{ scope.row.level1 }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="level2" :label="table_type == 1 ? '优秀' : '良好'" align="center">
                                <template slot-scope="scope">
                                    <div class="imgUrlbox"> <img :src="imgUrl[`imgUrl${scope.row.level2_compare}`]" alt="">
                                    </div>
                                    <span style="margin-left: 10px">{{ scope.row.level2 }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="level3" :label="table_type == 1 ? '良好' : '合格'" align="center">
                                <template slot-scope="scope">
                                    <div class="imgUrlbox"> <img :src="imgUrl[`imgUrl${scope.row.level3_compare}`]" alt="">
                                    </div>
                                    <span style="margin-left: 10px">{{ scope.row.level3 }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="level4" label="待提升" align="center">
                                <template slot-scope="scope">
                                    <div class="imgUrlbox"> <img :src="imgUrl[`imgUrl${scope.row.level4_compare}`]" alt="">
                                    </div>
                                    <span style="margin-left: 10px">{{ scope.row.level4 }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="conclusion" v-if="comment4">
                        <span class="prefix_title">结论2</span>
                        本校学生综合成绩来看，<span class="Comment_highlight">{{ getMaxValueMessage(grade_data)
                        }}</span>等级的学生人数{{ getMaxValueMessage(grade_data) == '各' ? '相同' : '居多' }}，{{ table_type ==
    1 ? '优秀率' : '优良率' }}为<span class="Comment_highlight">{{ comment4.k2 }}%</span>
                        <span v-if="comment4.k3">，相对上次周测有所{{ comment4.k3 }}。</span>
                        <!-- <span v-else>。</span> -->
                    </div>
                </div>
            </div>
            <!-- 全校各项成绩对比 -->
            <!-- <allGradesComparison_old ref="allGradesComparison_old" v-if="table_type == 1"></allGradesComparison_old> -->
            <allGradesComparison_week ref="allGradesComparison_week"></allGradesComparison_week>
            <!-- 各年级分析 -->
            <div class="flex-between interval2">
                <h1 class="First_level_heading">2.各年级分析</h1>
            </div>
            <div v-for="(item, index) in Gradelist" :key="index">
                <GradeAnalysisSch_week :Grade="Gradetype[item]" :schoolName="schoolName" :termValue="termValue"
                    :ref="`myChildRef${index}`" :weekValue="weekValue" :weekName="weekName" :table_type="3">
                </GradeAnalysisSch_week>
            </div>

        </div>
    </div>
</template>

<script>
import Short_form from './appraisalCharts/Short_form.vue'
import participationRate from './appraisalCharts/participationRate.vue'
import various_graphics from './appraisalCharts/various_graphics.vue'
import GradeAnalysisSch_week from './appraisalCharts/GradeAnalysisSch_week.vue'
import allGradesComparison_old from './allGradesComparison_old.vue'
import allGradesComparison_week from './allGradesComparison_week.vue'
export default {
    //import引入组件才能使用
    components: {
        Short_form,
        participationRate,
        various_graphics,
        GradeAnalysisSch_week,
        allGradesComparison_old,
        allGradesComparison_week
    },
    // props: ['term', 'month'],
    data() {
        return {
            //标题
            schoolName: '',
            //学期
            termValue: '',
            //周测类
            weekValue: '',
            weekName: '',
            // 参与情况
            //头部类别
            participation_construction: [{
                fieldName: 'grade',
                title: '年级'
            },
            {
                fieldName: 'register',
                title: '注册数'
            },
            {
                fieldName: 'totalNum',
                title: '参与总人数'
            },
            {
                fieldName: 'participationRates',
                title: '参与率'
            },
            ],
            //高度
            Height1: '258',
            row_height1: '21',
            //参与情况数据
            joinData: [],
            //结论1
            stuCount: '',
            pageCount: '',
            comment_one: true,
            //参与情况图表
            joinRate_graphics: {
                idName: 'participation',
                title: '坐姿错误人数',
                yData: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级'],
                semester1: [],
                semester2: [],
                colordata: [
                    'rgba(250, 193, 141, 1)',
                    'rgba(254, 105, 142,0.8)',
                    'rgba(176, 98, 242,1)',
                    'rgba(228, 152, 221, 0.8)',
                ],
            },
            //全校各等级学员占比
            grade_construction: [
                {
                    fieldName: 'level',
                    title: '等级'
                },
                {
                    fieldName: 'num',
                    title: '人数'
                },
                {
                    fieldName: 'proportion',
                    title: '占比'
                },
            ],
            //全校各等级学员占比数据
            grade_data: [],
            row_height2: '21',
            //与上次月测等级对比
            holdData: [],
            imgUrl: {
                imgUrl1: require('@/assets/newReport/greent.png'),
                imgUrl2: require('@/assets/newReport/or_r.png'),
                imgUrl3: require('@/assets/newReport/redx.png'),
            },
            //全校各等级学员占比 结论
            comment4: null,
            //全校成绩对比
            tablaData_school: [],
            various_graphics_data: [0, 0, 0, 0],
            //雷达图
            variousData: [{ name: '章法占格', max: 100 },
            { name: '结构组合', max: 100 },
            { name: '笔画形态', max: 100 },
            { name: '作品完整度', max: 100 }],
            //与上次月测等级对比
            tableData_compare: [],
            comment5: null,
            // 年级分析平铺
            Gradetype: {
                1: '一年级',
                2: '二年级',
                3: '三年级',
                4: '四年级',
                5: '五年级',
                6: '六年级',
            },
            // reportCode: 'LZ3-C-00', // 报告code:LZ3-C-00前测 LZ3-C-01后测
            Gradelist: [],
            table_type: null,
            reportValue: null,
        };
    },
    directives: {
        showValue: {
            inserted: function (el, binding) {
                // console.log(binding.value.value);
                // let data = binding.value.value;
                if (data.level1 == '/' && data.level2 == '/' && data.level3 == '/' && data.level4 == '/') {
                    el.innerHTML = '。';

                } else {

                }
            }
        }
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        // 参与情况
        joinInfo() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.weekValue,
                examType: 5
            }
            this.$Api.Report.joinInfoOverall(data)
                .then(res => {
                    let total = 0;
                    res.data.dataList.map(item => {
                        item.participationRates = item.participationRate + '%';
                        total += item.totalNum
                    })
                    if (!total) {
                        this.isEmpty = true;
                    } else {
                        this.isEmpty = false;
                    }
                    this.joinData = res.data.dataList;
                    this.stuCount = res.data.k1;
                    this.pageCount = res.data.k2;
                    if (this.joinData.length > 0) {
                        let num = this.joinData[0].participationRate;
                        for (let i = 1; i < this.joinData.length; i++) {
                            if (this.joinData[i].participationRate !== num) {
                                this.comment_one = false;
                                break
                            }
                        }
                    }
                }).catch(err => {
                    console.log(err)
                })
        },
        //各年级参与率图例
        joinRate() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.weekValue,
                examType: 5
            }
            this.$Api.Report.joinRateSch(data)
                .then(res => {
                    this.joinRate_graphics['semester1'] = res.data['thisTerm'];
                    this.joinRate_graphics['semester2'] = res.data['lastTerm'];
                })
                .catch(err => {
                    console.log(err)
                })

        },
        //全校各等级学员占比 图例
        stuProportion(data) {
            this.$echarts.init(document.getElementById("stuProportion")).dispose();
            let myChart = this.$echarts.init(
                document.getElementById("stuProportion")
            );
            myChart.setOption({
                title: {
                    text: '人数',
                    fontWeight: 'bold',
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                    top: '0%',
                    left: '45%',

                },
                grid: {
                    top: this.graphicRatio(60),
                    bottom: this.graphicRatio(40),
                    left: this.graphicRatio(40),
                    right: this.graphicRatio(40),
                },
                // tooltip: {
                //     trigger: 'axis',
                //     axisPointer: {
                //         type: 'shadow'
                //     },
                // },
                calculable: true,
                legend: {
                    show: false
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.table_type == 1 ? [
                            '优秀＋',
                            '优秀',
                            '良好',
                            '待提升',
                        ] : [
                            '优秀',
                            '良好',
                            '合格',
                            '待提升',
                        ],
                        axisLabel: {
                            fontSize: this.graphicRatio(14),
                        },
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '',
                        axisLabel: {
                            formatter: '{value}',
                            fontSize: this.graphicRatio(14),
                        }
                    },

                ],
                series: [
                    {
                        name: '人数',
                        type: 'bar',
                        barWidth: this.graphicRatio(35),
                        itemStyle: {
                            borderRadius: [this.graphicRatio(5), this.graphicRatio(5), 0, 0],
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(33, 146, 233, 1)'
                            }, {
                                offset: 1,
                                color: 'rgba(97, 229, 238,0.6)'
                            }])
                        },
                        label: {
                            show: true,
                            position: 'top',
                            color: "#000",
                            formatter: (params) => {
                                return `${params.value}`;
                            },
                            fontSize: this.graphicRatio(14),
                        },
                        data: data
                    },

                ]
            })
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //查年级
        getTakePartGradeList() {
            this.grade_options = [];
            this.Gradelist = [];
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.weekValue,
                examType: 5
            }
            this.$Api.Report.getTakePartGradeListSch(data).then(res => {
                this.Gradelist = res.data;
            }).catch(err => {
                console.log('导出失败:', err)
            })
        },
        //请求集合
        aggregate(termValue, weekValue, weekName, reportValue) {
            this.reportValue = reportValue;
            // console.log('termValue-------11', this.reportValue);

            this.termValue = termValue;
            this.weekValue = weekValue;
            this.weekName = weekName;
            //查看版本
            this.getIsNewDIm();
        },
        async getIsNewDIm() {
            //参与情况
            this.joinInfo();
            //各年级参与率
            this.joinRate();
            //全校各等级学员占比
            this.getTakePartGradeList();
            this.getstudentLvRatioCompareV2_month();
            this.getoneDScoreCompareV2_month();
            this.getoneDScoreCompareDiffV2_month();
        },
        //计算占比
        calculatePercentage(target, total) {

            if (total === 0) {
                return 0 + '%';
            }
            const percentage = (target / total) * 100;
            return percentage.toFixed(2) + '%'; // 保留两位小数并转换为百分比格式
        },
        // 结论5 计算各维度成绩前两名
        computedComment5(list, name) {
            let element = [];
            for (let index = 0; index < list.length; index++) {
                element.push(list[index][name]);
            }
            let Mathdata = Math.max(...element)

            const indices = element.reduce((acc, value, index) => {
                if (value === Mathdata) {
                    acc.push(list[index].indexRank);
                }
                return acc;
            }, []);
            if (indices.length <= 3) {
                return indices.join('、');
            } else {
                const firstFour = indices.slice(0, 3);
                const remaining = indices.length - 3;
                return firstFour.join('、') + (remaining > 1 ? '等' : '等');
            }
        },
        // 结论5 计算各维度与上学期对比
        computedLastTerm(list, name) {
            let className = [];
            let comment = '';
            list.map(item => {
                if (item[`${name}ben`] > item[`${name}last`]) {
                    className.push(item.indexRank)
                }
            })
            if (className.length == list.length) {
                comment = '各年级都有所提升'
            } else {
                comment = `${className.join('、')}相较上次月测有所提升`
            }
            return comment
        },
        compare(data, rate, type) {
            let element = [];
            for (let index = 0; index < data.length; index++) {
                element.push(data[index][rate]);
            }

            let Mathdata;
            if (type == 'maxRate') {
                //最大
                Mathdata = Math.max(...element);
            } else {
                // 最小
                Mathdata = Math.min(...element);
            }
            const indices = element.reduce((acc, value, index) => {
                if (value === Mathdata) {
                    acc.push(data[index].grade);
                }
                return acc;
            }, []);
            if (indices.length <= 3) {
                return indices.join('、');
            } else {
                const firstFour = indices.slice(0, 3);
                const remaining = indices.length - 3;
                return firstFour.join('、') + (remaining > 1 ? '等' : '等');
            }
        },
        getMaxValueMessage(obj) {
            let data = {
                level4: obj[3].num,
                level3: obj[2].num,
                level2: obj[1].num,
                level1: obj[0].num,
            };
            let classdata;
            if (this.table_type == 1) {
                classdata = {
                    4: '优秀＋',
                    3: '优秀',
                    2: '良好',
                    1: '待提升',
                };

            } else {
                classdata = {
                    4: '优秀',
                    3: '良好',
                    2: '合格',
                    1: '待提升',
                };
            }
            let maxArr = [];
            const values = Object.values(data);
            const maxValue = Math.max(...values);
            const maxField = Object.keys(data).find(key => data[key] === maxValue);
            let sameCount = 0;
            for (let i = 0; i < values.length; i++) {

                if (values[i] === maxValue) {
                    maxArr.push(i + 1)
                }
            }
            sameCount = maxArr.length;
            switch (sameCount) {
                case 4:
                    return "各";
                case 3:
                    return classdata[maxArr[0]] + '（和' + classdata[maxArr[1]] + '、' + classdata[maxArr[2]] + '）';
                case 2:
                    return classdata[maxArr[0]] + '（和' + classdata[maxArr[1]] + '）';
                default:
                    return classdata[maxArr[0]];
            }
        },
        async getstudentLvRatioCompareV2_month() {
            this.levelData = [];
            this.holdData = [];
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.weekValue,
                examType: 5
            };
            let res = await this.$Api.Report.getstudentLvRatioCompareV2_month(data);
            let grade_data_total = res.data.compare[0].level4num + res.data.compare[0].level3num + res.data.compare[0].level2num + res.data.compare[0].level1num;
            this.grade_data = [
                {
                    level: '优秀',
                    num: res.data.compare[0].level4num,
                    proportion: this.calculatePercentage(res.data.compare[0].level4num, grade_data_total),
                },
                {
                    level: '良好',
                    num: res.data.compare[0].level3num,
                    proportion: this.calculatePercentage(res.data.compare[0].level3num, grade_data_total),
                },
                {
                    level: '合格',
                    num: res.data.compare[0].level2num,
                    proportion: this.calculatePercentage(res.data.compare[0].level2num, grade_data_total),
                },
                {
                    level: '待提升',
                    num: res.data.compare[0].level1num,
                    proportion: this.calculatePercentage(res.data.compare[0].level1num, grade_data_total),
                }
            ];
            let stuProportionData = [res.data.compare[0].level4num, res.data.compare[0].level3num, res.data.compare[0].level2num, res.data.compare[0].level1num];
            this.stuProportion(stuProportionData)
            // 上下学期比较 1高于 2相等 3低于
            // res.data.compare[0].level1_compare = res.data.compare[0].level1 > res.data.compare[1].level1 ? 1 : res.data.compare[0].level1 == res.data.compare[1].level1 ? 2 : 3;
            // res.data.compare[0].level2_compare = res.data.compare[0].level2 > res.data.compare[1].level2 ? 1 : res.data.compare[0].level2 == res.data.compare[1].level2 ? 2 : 3;
            // res.data.compare[0].level3_compare = res.data.compare[0].level3 > res.data.compare[1].level3 ? 1 : res.data.compare[0].level3 == res.data.compare[1].level3 ? 2 : 3;
            // res.data.compare[0].level4_compare = res.data.compare[0].level4 > res.data.compare[1].level4 ? 1 : res.data.compare[0].level4 == res.data.compare[1].level4 ? 2 : 3;
            res.data.compare[0].level1_compare = this.compare_icon(res.data.compare[0].level1, res.data.compare[1].level1);
            res.data.compare[0].level2_compare = this.compare_icon(res.data.compare[0].level2, res.data.compare[1].level2);
            res.data.compare[0].level3_compare = this.compare_icon(res.data.compare[0].level3, res.data.compare[1].level3);
            res.data.compare[0].level4_compare = this.compare_icon(res.data.compare[0].level4, res.data.compare[1].level4);
            res.data.compare.map((item, index) => {
                const term = index === 0 ? '本次周测' : '上次周测';
                const obj = {
                    term,
                    level1: this.nullData(item.level4, term),
                    level2: this.nullData(item.level3, term),
                    level3: this.nullData(item.level2, term),
                    level4: this.nullData(item.level1, term),
                };
                if (index === 0) {
                    obj.level1_compare = item.level4_compare;
                    obj.level2_compare = item.level3_compare;
                    obj.level3_compare = item.level2_compare;
                    obj.level4_compare = item.level1_compare
                }
                this.holdData.push(obj);
                // let obj;
                // if (index == 0) {
                //     obj = {
                //         term: '本次周测',
                //         level1: item.level4 ? item.level4 + '%' : 0 + '%',
                //         level2: item.level3 ? item.level3 + '%' : 0 + '%',
                //         level3: item.level2 ? item.level2 + '%' : 0 + '%',
                //         level4: item.level1 ? item.level1 + '%' : 0 + '%',
                //     }
                // } else {
                //     if (item.level4 == 0 && item.level3 == 0 && item.level2 == 0 && item.level1 == 0) {

                //         obj = {
                //             term: '上次周测',
                //             level1: item.level4 ? item.level4 + '%' : 0 + '%',
                //             level2: item.level3 ? item.level3 + '%' : 0 + '%',
                //             level3: item.level2 ? item.level2 + '%' : 0 + '%',
                //             level4: item.level1 ? item.level1 + '%' : 0 + '%',
                //         }
                //     } else {
                //         obj = {
                //             term: '上次周测',
                //             level1: item.level4 ? item.level4 + '%' : '/',
                //             level2: item.level3 ? item.level3 + '%' : '/',
                //             level3: item.level2 ? item.level2 + '%' : '/',
                //             level4: item.level1 ? item.level1 + '%' : '/',
                //         }
                //     }
                // }

                // if (index == 0) {
                //     obj.level1_compare = item.level4_compare;
                //     obj.level2_compare = item.level3_compare;
                //     obj.level3_compare = item.level2_compare;
                //     obj.level4_compare = item.level1_compare;
                // }
                // this.holdData.push(obj);

            });
            this.comment4 = res.data;
        },
        async getoneDScoreCompareV2_month() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.weekValue,
                examType: 5
            };
            let res = await this.$Api.Report.getoneDScoreCompareV2_month(data);
            // console.log('res', res);
            this.tablaData_school = res.data;
            let fullSchoolRankStudents = this.tablaData_school.filter(student => student.indexRank === '全校');
            this.various_graphics_data = [fullSchoolRankStudents[0].centerPos, fullSchoolRankStudents[0].gridSize, fullSchoolRankStudents[0].glyphGrav, fullSchoolRankStudents[0].comboRel, fullSchoolRankStudents[0].rewindRel, fullSchoolRankStudents[0].multiDist, fullSchoolRankStudents[0].strokeRel, fullSchoolRankStudents[0].integrity];
            this.$refs.allGradesComparison_week.parameterall1(this.tablaData_school, this.various_graphics_data);
        },
        async getoneDScoreCompareDiffV2_month() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.weekValue,
                examType: 5
            };
            let res = await this.$Api.Report.getoneDScoreCompareDiffV2_month(data);
            // 各年级本学期与上学期对比 1高于 2相等 3低于
            res.data.resultDiffList.map(item => {
                item.centerPos_compare = item.centerPos > item.centerPosLast ? 1 : item.centerPos == item.centerPosLast ? 2 : 3;
                item.gridSize_compare = item.gridSize > item.gridSizeLast ? 1 : item.gridSize == item.gridSizeast ? 2 : 3;
                item.glyphGrav_compare = item.glyphGrav > item.glyphGravLast ? 1 : item.glyphGrav == item.glyphGravLast ? 2 : 3;
                item.rewindRel_compare = item.rewindRel > item.rewindRelLast ? 1 : item.rewindRel == item.rewindRelLast ? 2 : 3;
                item.multiDist_compare = item.multiDist > item.multiDistLast ? 1 : item.multiDist == item.multiDistLast ? 2 : 3;
                item.strokeRel_compare = item.strokeRel > item.strokeRelLast ? 1 : item.strokeRel == item.strokeRelLast ? 2 : 3;
                item.comboRel_compare = item.comboRel > item.comboRelLast ? 1 : item.comboRel == item.comboRelLast ? 2 : 3;
                item.integrity_compare = item.integrity > item.integrityLast ? 1 : item.integrity == item.integrityLast ? 2 : 3;
            })
            this.tableData_compare = res.data.resultDiffList;
            this.comment5 = res.data;
            // console.log('this.tableData_compare', this.tableData_compare);
            this.$refs.allGradesComparison_week.parameterall2(this.tableData_compare, this.comment5);
        },
        async getgradeGradeRatioV2_month() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termValue,
                testId: this.weekValue,
                examType: 5
            };
            let res = await this.$Api.Report.getgradeGradeRatioV2_month(data);
            // console.log('res', res);
        },
        // 判断空数据
        nullData(value) {
            if (this.table_type == 1) {
                return value === undefined || value === null || value === 0 ? '/' : value + '%';
            } else {
                return value === undefined || value === null ? '/' : value + '%';
            }
        },
        //返回箭头
        compare_icon(value1, value2) {
            if (value1 > value2) {
                return 1
            } else if (value1 < value2) {
                return 3
            } else {
                return 2
            }
        },

    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.schoolName = this.$route.query.schoolName;
    },
    beforeCreate() { },//生命周期：创建之前
    beforeMount() { },//生命周期：挂载之前
    beforeUpdate() { },//生命周期：更新之前
    updated() { },//生命周期：更新之后
    beforeDestroy() { },//生命周期：销毁之前
    destroyed() { },//生命周期：销毁完成
    activated() { },//如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style lang="less" scoped>
@import url("./less/monthTest.less");
</style>