import { render, staticRenderFns } from "./frontBackaAppraisal.vue?vue&type=template&id=dc3e56ea&scoped=true"
import script from "./frontBackaAppraisal.vue?vue&type=script&lang=js"
export * from "./frontBackaAppraisal.vue?vue&type=script&lang=js"
import style0 from "./frontBackaAppraisal.vue?vue&type=style&index=0&id=dc3e56ea&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc3e56ea",
  null
  
)

export default component.exports